import ScrollTop from './components/scrollToTop/scrollTop'
import RouterClass from './routes/router'
import { Toaster } from 'sonner'

function App() {
    return (
        <div className="haul247App_Container">
            <Toaster />
            <RouterClass />
            <ScrollTop />
        </div>
    )
}

export default App
