import { createClient } from 'contentful'

// const host = process.env.NODE_ENV === 'production' ? 'cdn.contentful.com' : 'preview.contentful.com'
class ContentfulClient {
    constructor(contentType) {
        this.client = createClient({
            space: 'bb8jztk6njbo',
            accessToken: 'y9rY2R-M5n_GeOtxSnMmMhUEIGrBlrEqDqwOgE_zbNM',
            // host,
        })

        this.contentType = contentType
    }

    async getEntries(tag) {
        try {
            const response = await this.client.getEntries({
                content_type: this.contentType,
                ...(tag && { 'metadata.tags.sys.id[in]': tag }),
            })
            const entries = response.items.map((el) => ({
                ...el.fields,
                image: el?.fields?.image?.fields?.file.url || '',
            }))
            return entries
        } catch (error) {
            console.error(error)
            return null
        }
    }

    async getEntry(slug) {
        try {
            const response = await this.client.getEntries({
                content_type: this.contentType,
                'fields.slug': slug,
            })
            const entry = response.items.map((el) => ({
                ...el.fields,
                image: el?.fields?.image?.fields?.file?.url,
            }))
            return entry[0]
        } catch (error) {
            console.error(error)
            return null
        }
    }

    async getTags() {
        try {
            const response = await this.client.getTags()
            let tags = response.items.map((el) => ({
                name: el.name,
                id: el.sys.id,
            }))
            return tags
        } catch (error) {
            console.error(error)
            return null
        }
    }
}

export default ContentfulClient
