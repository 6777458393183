import React from 'react'
import Hero from '../../components/ui/hero'
import TrackingTrackerIcon from '../../assets/icons/tracking-track.svg'
import BenefitSection from '../../components/ui/benefitSection'
import WorldIcon from '../../assets/icons/benefits/www.svg'
import SeamlessIcon from '../../assets/icons/benefits/seamless.svg'
import ThumbsIcon from '../../assets/icons/benefits/thumbs.svg'
import StatsCount from '../../components/ui/statsCount'
import Faq from '../../components/ui/faq'
import ContactForm from '../../components/ui/contactForm'
import Footer from '../../components/ui/layout/footer'
import Testimonial from '../../components/ui/testimonial'
import SEOTag from '../../components/seoTag'

const Tracking = () => {
    return (
        <>
            <SEOTag image="" title="Haul247 | Real-time Visibility | Real time Tracking" url="https://www.haul247.co/solutions/tracking" description="" />
            <div className="font-inter text-[#242424]">
                <Hero
                    icon={TrackingTrackerIcon}
                    heading={'Tracking Tomorrow, Today'}
                    subHeading={`At Haul247, we believe in transparency, reliability, and the power of knowledge. That's why our real-time tracking solution isn't just about monitoring shipments—it's about empowering you with unparalleled visibility and control over your goods in transit.`}
                />

                <section className="mb-20">
                    <BenefitSection
                        benefits={[
                            {
                                heading: 'Precision at Your Fingertips',
                                subText: `Experience logistics in real-time. Our cutting-edge tracking system allows you to monitor every step of your shipment's journey. From pickup to delivery, gain instant access to crucial details, ensuring you're always in the loop.`,
                                icon: WorldIcon,
                            },
                            {
                                heading: 'Seamless Integration, Seamless Insights',
                                subText: `Integrate our tracking solutions effortlessly into your existing systems. Our user-friendly interface provides intuitive insights, enabling you to make informed decisions and optimize operations efficiently.`,
                                icon: SeamlessIcon,
                            },
                            {
                                heading: 'Reliability Redefined',
                                subText: `Trust in precision. Our real-time tracking isn't just a feature; it's a promise. Rest assured, your cargo's location, status, and estimated delivery are at your fingertips, empowering you with the information you need, precisely when you need it.`,
                                icon: ThumbsIcon,
                            },
                        ]}
                    />
                </section>

                <StatsCount
                    stats={[
                        { number: 30, text: 'Increase in On-Time Delivery Rate', active: false },
                        { number: 20, text: 'Reduction in Customer Support Time', active: true },
                        { number: 90, text: 'Actionable Insights for Informed Decision-Making', active: false },
                    ]}
                />

                <Testimonial
                    role={`GB Foods Logistics Manager`}
                    writer={`Tosin Ajayi`}
                    message={`Working with Haul247 has provided great insight to how well I can organise my company’s haulage needs. I can manage, monitor and see all my trips in one place. It’s amazing to see an app that can help do both haulage and warehousing seamlessly. Haul247 also puts a premium on safety of our goods and the customer service is great.`}
                />
            </div>
            <div className="font-br-firma">
                <div className="bg-[#FFFFF7]">
                    <Faq
                        faqs={[
                            {
                                question: 'How does real-time tracking benefit my business?',
                                answer: `Real-time tracking offers unparalleled visibility into your shipments, allowing you to monitor their progress at every stage. This ensures proactive decision-making, reduced risks of delays, and enhanced customer satisfaction through accurate updates.`,
                            },
                            {
                                question: 'How frequently are the tracking updates provided?',
                                answer: `Our system provides real-time updates, offering information on your shipments as frequently as they move. You&#39;ll have access to immediate and accurate data regarding the location and status of your cargo.`,
                            },
                            {
                                question: `Is your tracking system user-friendly?`,
                                answer: `Absolutely. Our interface is intuitive and user-friendly, ensuring ease of use for all levels of expertise. You&#39;ll have access to comprehensive tracking data without any technical hassle.`,
                            },
                            {
                                question: `What happens if there's a delay in the shipment?`,
                                answer: `Our real-time tracking system enables you to anticipate and mitigate delays proactively. In the event of a delay, you will receive immediate notifications, allowing you to take corrective actions swiftly.`,
                            },
                            {
                                question: `How secure is the tracking data?`,
                                answer: `Protecting your data is our priority. Our tracking system employs robust security measures to ensure that your shipment data remains confidential and secure at all times.`,
                            },
                            {
                                question: `Can I track multiple shipments simultaneously?`,
                                answer: `Absolutely. Our system is designed to handle multiple shipments concurrently. You can monitor and manage numerous shipments in real time without any hassle.`,
                            },
                            {
                                question: `What kind of support do you offer for implementing the tracking solution?`,
                                answer: `We provide comprehensive 24/7 support throughout the adaptation process. Our team will assist you in integrating the tracking solution seamlessly into your logistics framework and provide training as needed.`,
                            },
                            {
                                question: `How can I get started with your real-time tracking solution?`,
                                answer: `Getting started is easy. Once you’re on-boarded as a client, an account manager will contact you and we&#39;ll guide you through the process, providing demos and all the information you need to begin experiencing the benefits of real-time tracking.`,
                            },
                        ]}
                    />
                </div>

                <ContactForm />

                <Footer />
            </div>
        </>
    )
}

export default Tracking
