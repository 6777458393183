import React from 'react'
import landingImg from '../../../assets/images/caseStudy.png'
import ContactForm from '../../../components/ui/contactForm'
import Footer from '../../../components/ui/layout/footer'

const CaseStudy = () => {
    function openContactForm() {
        /** Google Tag Manager custom event */
        window.dataLayer.push({
            event: 'click_cta',
        })

        const contactSection = document.getElementById('contact_sales')
        contactSection.scrollIntoView({ behavior: 'smooth' })
    }
    return (
        <>
            <div className="font-inter text-[#242424]">
                <section className="flex w-full lg:h-[690px]">
                    <div className="bg-[#FAFAF7] grid place-content-center py-12 xl:py-0 px-6 lg:px-[112px] grow">
                        <h1 className="font-inter-bold font-semibold text-4xl mb-12">A Successful Business Stems from Satisfied Customers</h1>
                        <h6 className="text-base leading-[26px] mb-9">
                            In the tapestry of our customer success stories, each thread weaves a tale of triumph. Discover how our tailored solutions have
                            propelled businesses to new heights, turning challenges into opportunities and aspirations into achievements. Join us on a journey
                            where success is not just a destination but a shared narrative of growth and satisfaction.
                        </h6>
                        <button
                            onClick={openContactForm}
                            className="text-sm mt-auto font-inter rounded-[5px] px-4 md:px-[26px] py-[13px] text-white bg-gradient-to-r from-[#f0aa22] to-[#f05822] w-fit h-[51px] font-semibold"
                        >
                            Become a customer
                        </button>
                    </div>

                    <div className="shrink-0 bg-slate-300 hidden xl:block">
                        <img src={landingImg} alt="img" />
                    </div>
                </section>

                <section className="body-padding mt-10 md:mt-[60px]">
                    <h1 className="font-semibold text-2xl md:text-[40px] md:leading-[50px] my-12">Case Studies</h1>
                </section>
            </div>

            <div className="font-br-firma">
                <ContactForm />
                <Footer />
            </div>
        </>
    )
}

export default CaseStudy
