import React from 'react'
import Hero from '../../components/ui/hero'
import DeliveryCarrIcon from '../../assets/icons/ocean-transportation.svg'
import BenefitSection from '../../components/ui/benefitSection'
import BoxReturnIcon from '../../assets/icons/benefits/box-return.svg'
import CycleIcon from '../../assets/icons/benefits/optimizedReturn.svg'
import EnviromentIcon from '../../assets/icons/benefits/enviroment.svg'
import BoxIcon from '../../assets/icons/benefits/box-to-box.svg'
import Faq from '../../components/ui/faq'
import ContactForm from '../../components/ui/contactForm'
import Footer from '../../components/ui/layout/footer'
import Testimonial from '../../components/ui/testimonial'
import Cta from '../../components/ui/cta'
import StatsCount from '../../components/ui/statsCount'
import SEOTag from '../../components/seoTag'

const ReverseLogistics = () => {
    return (
        <>
            <SEOTag image="" title="Haul247 | REVERSE LOGISTICS" url="https://www.haul247.co/solutions/reverse-logistics" description="" />
            <div className="font-inter text-[#242424]">
                <Hero
                    icon={DeliveryCarrIcon}
                    heading={'Reverse Your Way with Ease'}
                    subHeading={`We recognize that the logistics journey doesn’t end at delivery - it extends to returns and reverse logistics. Our reverse logistics freight on delivery solutions are tailored to simplify your return process, ensuring efficiency, cost-effectiveness, and customer satisfaction.`}
                />

                <section className="mb-20">
                    <BenefitSection
                        benefits={[
                            {
                                heading: 'Seamless Returns Process',
                                subText: `Our services ensure a smooth and efficient returns process, from arranging pickup to the secure handling and transportation of returned goods.`,
                                icon: BoxReturnIcon,
                            },
                            {
                                heading: 'Optimized Resource Utilization',
                                subText: `Streamline operations. Our reverse logistics solutions optimize resource utilization by efficiently managing returned inventory and reducing unnecessary holding costs.`,
                                icon: CycleIcon,
                            },
                            {
                                heading: 'Minimize Environmental Impact',
                                subText: `Sustainable returns. By optimizing reverse logistics, we minimize environmental impact by reducing waste and optimizing the use of transportation resources.`,
                                icon: EnviromentIcon,
                            },
                            {
                                heading: 'Effortless Returns Management',
                                subText: `Handling returns can be complex. Our reverse logistics solutions streamline the process, making returns management effortless. With freight on delivery, we facilitate easy pickup and return of goods, enhancing customer experience.`,
                                icon: BoxIcon,
                            },
                        ]}
                    />
                </section>

                <StatsCount
                    stats={[
                        { number: 35, text: 'Reduction in Return Processing Time', active: false },
                        { number: 25, text: 'Increase in Customer Satisfaction', active: true },
                        { number: 20, text: 'Reduction in Return Shipping Expenses', active: false },
                    ]}
                />

                <Testimonial
                    backgroundColor={`bg-[#FAFAF7]`}
                    role={`Okeymore Global Limited, Truck owner`}
                    writer={`Dike Okechukwu`}
                    message={`Before Haul247 I could barely get enough trips to maximise my trucks usage. Partnering with Haul247 has helped increase my trucks usage has increased significantly helping to make us profitable. We do over 70% more trips than we used to do on our own. Kudos! Haul247 looking forward to doing more business!`}
                />
            </div>
            <div className="font-br-firma mt-8">
                <div className="bg-[#FFFFF7]">
                    <Faq
                        faqs={[
                            {
                                question: 'What exactly is reverse logistics?',
                                answer: `Reverse logistics refers to the process of managing returns and handling products that move from the customer back to the seller or manufacturer. It involves activities such as return pickups, processing returned items, and managing inventory.`,
                            },
                            {
                                question: 'How does freight on delivery simplify the returns process?',
                                answer: `Freight on delivery simplifies returns by facilitating easy pickup and return of goods. It allows for a smooth and efficient process where the carrier collects the returned item upon delivery to the customer.`,
                            },
                            {
                                question: 'Can freight on delivery services be customized for different types of shipments?',
                                answer: `Absolutely. Our freight-on-delivery services are customizable to suit various types of shipments, ensuring tailored solutions that fit your specific business needs.`,
                            },
                            {
                                question: `How does freight on delivery benefit businesses?`,
                                answer: `Freight on delivery benefits your business by simplifying the returns process,improving operational efficiency, reducing costs associated with return shipping, and enhancing customer satisfaction through hassle-free returns.`,
                            },
                            {
                                question: `Is freight on delivery available for both domestic and international shipments?`,
                                answer: `Our freight-on-delivery services cover only domestic shipments, providing efficient and reliable options for return logistics regardless of the shipment&#39;s destination.`,
                            },
                            {
                                question: `Are there limitations on the size or weight of items eligible for freight on delivery services?`,
                                answer: `While certain limitations may apply depending on the transportation mode, our freight-on-delivery services cater to a wide range of shipment sizes and weights. We can provide solutions for various cargo sizes.`,
                            },
                            {
                                question: `How can I integrate freight on delivery services into my existing logistics framework?`,
                                answer: `Integrating freight on delivery services is simple. Get in touch with our team, and we&#39;ll guide you through the process, explaining available options and assisting in incorporating these services seamlessly into your operations.`,
                            },
                            {
                                question: `How does freight on delivery contribute to environmental sustainability?`,
                                answer: `Freight on delivery contributes to environmental sustainability by optimizing reverse logistics, reducing waste, and minimizing the environmental impact associated with return shipments and logistics operations.`,
                            },
                        ]}
                    />
                </div>

                <Cta
                    pillText={'Ready to streamline your returns process?'}
                    ctaHeading={`Experience hassle-free reverse logistics with freight on delivery.`}
                    ctaButton={`Optimize Your Returns Today!`}
                    headingWidth={'lg:w-[703px]'}
                />

                <ContactForm />

                <Footer />
            </div>
        </>
    )
}

export default ReverseLogistics
