import React from 'react'
import HAUL247LOGO from '../../../assets/logos/haul-247-logo.svg'
import '../../footer/footer.scss'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className="footer pb-5">
            <div className="footerArea body-padding pad-2x brand-color justify-between gap-5 xl:gap-28 flex-col xl:flex-row">
                <section className="section1 shrink-0">
                    <div className="logo_footer">
                        <img src={HAUL247LOGO} alt="haul247 logo" />
                    </div>

                    <div className="about_footer">
                        Haul247, an end-to-end logistics tech API platform that connects businesses to haulage and warehousing assets all in one ecosystem...
                    </div>

                    <div className="socialMedia">
                        <div className="socials_icon">
                            <a href="https://instagram.com/haul247.co">
                                <i className="fi fi-brands-instagram"></i>
                            </a>

                            <a href="https://twitter.com/haul247">
                                <i className="fi fi-brands-twitter"></i>
                            </a>

                            <a href="https://www.facebook.com/Haul247">
                                <i className="fi fi-brands-facebook"></i>
                            </a>

                            <a href="https://www.linkedin.com/company/haul-247">
                                <i className="fi fi-brands-linkedin"></i>
                            </a>
                        </div>
                    </div>
                </section>

                <div className="flex  flex-wrap xl:flex-row w-full gap-8">
                    <section className="w-[150px] shrink-0">
                        <div className="head_tag">Products</div>

                        <Link to="/products/haulage">Haulage</Link>
                        <Link to="/products/warehousing">Warehouse</Link>
                        <Link to="/products/port-operations">Port Operations</Link>
                        <Link to="/products/raw-material">Raw Materials Shipment</Link>
                        <Link to="/products/supply-chain">Supply Chain Optimization</Link>
                    </section>
                    <section className="w-[150px] shrink-0">
                        <div className="head_tag">Resources</div>

                        <Link to="/resources/blog">Blog</Link>
                        <Link to="/resources/faq">Faq</Link>
                        <Link to="/resources/e-guides">E-guides</Link>
                        <Link to="/resources/glossary">Glossary</Link>
                        <Link to="/resources/case-studies">Case Studies</Link>
                    </section>
                    <section className="w-[150px] shrink-0">
                        <div className="head_tag">Solutions</div>

                        <Link to="/solutions/tracking">Real-time Tracking</Link>
                        <Link to="/solutions/insurance">Transit Insurance</Link>
                        <Link to="/solutions/delivery">Expedited Delivery</Link>
                        <Link to="/solutions/reverse-logistics">Reverse Logistics Freight on Delivery</Link>
                    </section>
                    <section className="w-[170px] shrink-0">
                        <div className="head_tag">Company</div>

                        <Link to="/about">About</Link>
                        <Link to="/careers">Careers</Link>
                        <Link to="/impact">Our Impact</Link>
                    </section>

                    <section className="w-[170px] shrink-0">
                        <div className="head_tag">Help / Support</div>

                        <a href="#contact_sales">Contact Us</a>
                        <a href="https://play.google.com/store/apps/details?id=com.haul247" target="_blank" rel="noreferrer">
                            Download App
                        </a>
                    </section>

                    <section className=" ">
                        <div className="head_tag">Contact Information</div>

                        <div className="infoContact">
                            <a href="https://api.whatsapp.com/send?phone=2349010003247" className="info-p" target="_blank" rel="noreferrer">
                                <i className="fi fi-rr-phone-call"></i>
                                <p>09010003247</p>
                                <i className="fi fi-brands-whatsapp"></i>
                            </a>

                            <a href="mailto:service@haul247.co" className="info-p">
                                <i className="fi fi-rr-envelope"></i>
                                <p>service@haul247.co</p>
                            </a>

                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.google.com/maps/dir/6.4278453,3.4902402/haul247/@6.4378594,3.423198,13z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x103bf52916ecd6e9:0x71624b0fb91ce38c!2m2!1d3.4264887!2d6.4351953"
                                className="info-p"
                            >
                                <i className="fi fi-rr-marker"></i>
                                <p>
                                    1 Engineering Close, {<br></br>}
                                    Victoria Island 106104, {<br></br>}
                                    Lagos State
                                </p>
                            </a>
                        </div>
                    </section>
                </div>
            </div>

            <div className="copyright text-center px-6 pt-5 pb-0">
                © 2024 Haul247. All rights reserved. <strong>Haul247.co</strong> is a registered trademark of <strong>Haul247</strong>. All other marks, names,
                and logos mentioned herein are the property of their respective owners.
            </div>
        </div>
    )
}

export default Footer
