import React from 'react';
import './impact.scss'
import ImpactItem from './ImpactItem';
import sdg1 from '../../assets/svg/sdg1.svg'
import sdg2 from '../../assets/svg/equality.svg'
import sdg3 from '../../assets/svg/growth.svg'
import sdg4 from '../../assets/svg/sdg9.png'

const Impact = () => {

    return (

        <div className="impactContainer">

            <div className="impact fullpad">

                <div className="topArea">

                    <h1>It’s our utmost pride that our lives and environment are <span>impacted positively!</span></h1>

                    <p>We are intentional about having a positive impact on our immediate society, Nigeria, Africa, the tech and logistics community. We achieve this through our platform, our services, our partners  and clients.</p>

                </div>

            </div>

            <div className="otherInfo fullpad">

                <div className="heading lips">Our Impact</div>

                <div className="ourImpact">

                    <div className="impactItem">

                        <div className="content">

                            <div className="title"> <i class="fi fi-sr-eye"></i> <p>Visibility</p></div>
                            <div className="body">

                                <p className='top'>

                                    <strong>To Clients : </strong> {<br></br>}
                                    
                                    We have been able to bring greater visibility to businesses by providing better logistics management. This has saved total production cost by 30%, curb supply chain problems and gain insights for more opportunities. 

                                </p>

                                <p>

                                    <strong>To Partners : </strong> {<br></br>}
                                    
                                    Our partners now enjoy increased revenue from proper asset utilization of trucks and warehouses due to the visibility to businesses accessed on Haul247's platform.


                                </p>

                            </div>

                        </div>

                    </div>

                    <ImpactItem />

                </div>

                <div className="heading"> OUR SDGs </div>

                <div className="sdgs">

                    <div className="sdg sdg1">

                        <div className="headerIcon">

                            <div className="icon"> 
                                <img src={sdg1} alt="sdg1" />
                            </div>

                            <p>Zero {<br></br>} Hunger</p>

                        </div>

                        <div className="bodyText">
                            It is no news that as of 2020, 8.9% of the world's population live in hunger. 250 million out of that number live in Africa. 
                            As an organization, we have put it upon ourselves to contribute to the reduction of that statistics drastically before 2030 by ensuring accessibility to warehouses for agricultural produce (to reduce spoilage), safe and swift transportation of these produce across Africa.

                        </div>

                    </div>

                    <div className="sdg sdg2">

                        <div className="headerIcon">

                            <div className="icon"> 
                                <img src={sdg2} alt="sdg2" />
                            </div>

                            <p>Gender {<br></br>} Equality</p>

                        </div>

                        <div className="bodyText">
                           We are particular about ensuring the eradication of gender discrimination in Africa. We give no room for bias, especially towards women, by creating equal opportunities and platforms for all our employees, partners and clients.

                        </div>

                    </div>

                    <div className="sdg sdg3">

                        <div className="headerIcon">

                            <div className="icon"> 
                                <img src={sdg3} alt="sdg3" />
                            </div>

                            <p>Decent Work & {<br></br>} Economic Growth</p>

                        </div>

                        <div className="bodyText">

                        Logistics, which is part of the supply chain, is one key element which is being disrupted. This adversely affects the economic growth of nations and in turn results in increasing unemployment.
                        Haul247 is strategically refining the Logistics ecosystem to ensure this economic gap is closed, cost effectiveness for businesses, better income and increased job creation.


                        </div>

                    </div>

                    <div className="sdg sdg4">

                        <div className="headerIcon">

                            <div className="icon"> 
                                <img src={sdg4} alt="sdg4" />
                            </div>

                            <p>Industries, Innovation {<br></br>} and Infrastructure</p>

                        </div>

                        <div className="bodyText">

                        Inclusive and sustainable industrialization, together with innovation and infrastructure, can unleash dynamic and competitive economic forces that generate employment and income. 
                        We are at the fore-front of promoting new technologies, facilitating international trade and enabling efficient use of resources.




                        </div>

                    </div>

                </div>
            </div>

        </div>

    );
}

export default Impact;
