import React, { useState, useEffect, useRef } from 'react'
import './header.scss'
import HAUL247LOGO from '../../assets/logos/haul-247-logo.svg'
import NGFLAG from '../../assets/svg/nigeriaflag.svg'
import GHFLAG from '../../assets/svg/ghflag.svg'
// import BRFLAG from '../../assets/svg/beninflag.svg';
// import EGFLAG from '../../assets/svg/egflag.svg'
import BRG from '../../assets/icons/menu.png'
import WarehouseIcon from '../../assets/icons/warehouse.svg'
import PortIcon from '../../assets/icons/port.svg'
import SupplyIcon from '../../assets/icons/supplyChain.svg'
import TruckIcon from '../../assets/icons/benefits/trucks.svg'
import WheelBarrow from '../../assets/icons/wheelBarrow.svg'
import { useNavigate, useLocation } from 'react-router-dom'
import TrackingTrackerIcon from '../../assets/icons/tracking-track.svg'
import ProtectionIcon from '../../assets/icons/protection.svg'
import DeliveryCarrIcon from '../../assets/icons/delivery-car.svg'
import Logistic from '../../assets/icons/ocean-transportation.svg'
import NavItems, { NavItemSmall, NavItemMobile } from './navItems.js'
import BlogIcon from '../../assets/icons/blog_svgrepo.com.svg'
import EGuideIcon from '../../assets/icons/pantone_svgrepo.com.svg'
import FAQIcon from '../../assets/icons/faq_svgrepo.com.svg'
import glossaryIcon from '../../assets/icons/glossary.svg'
import VideoIcon from '../../assets/icons/video-frame.svg'
import researchIcon from '../../assets/icons/market-research.svg'

const Header = () => {
    const [countryShow, setCountryShow] = useState(false)
    const [activeSublinkIndex, setActiveSublinkIndex] = useState(null)
    const [menu, setMenu] = useState(false)
    const nav = useRef(null)

    let navigate = useNavigate()
    let locate = useLocation()
    const { pathname } = locate

    useEffect(() => {
        document.addEventListener('scroll', (event) => {
            const scrollPosition = event.target.scrollingElement.scrollTop
            if (scrollPosition > 10) {
                if (!nav?.current?.classList?.contains('scrolled-down')) {
                    nav.current.classList.add('scrolled-down')
                }
            } else {
                if (nav.current?.classList?.contains('scrolled-down')) {
                    nav.current.classList.remove('scrolled-down')
                }
            }
        })
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
        setCountryShow(false)
        setActiveSublinkIndex(null)
        setMenu(false)
    }, [pathname])

    function clickContactButton() {
        /** Google Tag Manager custom event */
        window.dataLayer.push({
            event: 'contact_sales_button_click',
        })

        setMenu(false)
        const contactSection = document.getElementById('contact_sales')
        contactSection.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <div ref={nav} className="haul247_HEADER  px-6 md:px-[50px] xl:px-[100px]">
            <div className="logo" onClick={() => navigate('/')}>
                <img src={HAUL247LOGO} alt="Haul247 logo" />
            </div>

            <div className="navs hidden lg:flex">
                <ul className="  text-sm items-center  md:space-x-3 lg:space-x-6 flex">
                    <NavItems
                        menu={'Products'}
                        subMenus={[
                            { text: 'Haulage', subText: 'Your Cargo’s Journey, Our Commitment', icon: TruckIcon, link: '/products/haulage' },
                            { text: 'Warehousing', subText: 'Space Perfected, Inventory Protected', icon: WarehouseIcon, link: '/products/warehousing' },
                            { text: 'Port Operations', subText: 'Smooth Sailing Logistics', icon: PortIcon, link: '/products/port-operations' },
                            {
                                text: 'Supply Chain Optimization',
                                subText: 'Insights To Drive Your Supply Chain',
                                icon: SupplyIcon,
                                link: '/products/supply-chain',
                            },
                            {
                                text: 'Raw Materials Shipment',
                                subText: 'Fuelling Innovation, Delivering Excellence',
                                icon: WheelBarrow,
                                link: '/products/raw-material',
                            },
                        ]}
                    />
                    <NavItems
                        menu="Solutions"
                        subMenus={[
                            {
                                text: 'Real-time Tracking through GPS',
                                subText: 'Journey Insight, Instant Peace',
                                icon: TrackingTrackerIcon,
                                link: '/solutions/tracking',
                            },
                            { text: 'Transit Insurance', subText: 'Coverage that travels the distance', icon: ProtectionIcon, link: '/solutions/insurance' },
                            {
                                text: 'Expedited Delivery',
                                subText: 'Accelerate your Deliveries, Amplify your Impact',
                                icon: DeliveryCarrIcon,
                                link: '/solutions/delivery',
                            },
                            {
                                text: 'Reverse Logistics Freight on Delivery',
                                subText: 'Forward Momentum, Even in Reverse',
                                icon: Logistic,
                                link: '/solutions/reverse-logistics',
                            },
                        ]}
                    />

                    <NavItems
                        menu="Resources"
                        subMenus={[
                            {
                                text: 'Blog',
                                subText: 'Smart Solutions For Smoother Logistics',
                                icon: BlogIcon,
                                link: '/resources/blog',
                            },
                            {
                                text: 'E-guides',
                                subText: 'Logistics Solutions at Your Fingertips: Explore Our E-Guide',
                                icon: EGuideIcon,
                                link: '/resources/e-guides',
                            },
                            {
                                text: 'Case Studies',
                                subText: 'From Challenges to Solutions: Learn about how we turned theories into realities.',
                                icon: researchIcon,
                                link: '/resources/case-studies',
                            },
                            {
                                text: 'FAQS',
                                subText: 'Find answers to every possible questions you seek',
                                icon: FAQIcon,
                                link: '/resources/faq',
                            },
                            {
                                text: 'Video',
                                subText: 'Capturing Logistics Impact through Video',
                                icon: VideoIcon,
                                link: '/resources/videos',
                            },
                            {
                                text: 'Glossary',
                                subText: 'From A to Z: Your Logistics Tech Glossary Encyclopedia',
                                icon: glossaryIcon,
                                link: '/resources/glossary',
                            },
                        ]}
                    />

                    <NavItemSmall
                        menu="Company"
                        subMenus={[
                            { text: 'About Us', link: '/about' },
                            { text: 'Careers', link: '/careers' },
                            { text: 'Our Impact', link: '/impact' },
                        ]}
                    />
                </ul>

                <div className="button-section">
                    <a href="#contact_sales" className="haul247-button-main haul247-button-outline">
                        Contact Us
                    </a>
                    <a href="https://client.haul247.co/login" target="_blank" rel="noopener noreferrer" className="haul247-button-main">
                        Sign Up
                    </a>
                </div>
            </div>

            {menu ? (
                <div className="navs showNav z-[9999999] h-screen">
                    <div className="w-full h-full overflow-scroll">
                        <NavItemMobile
                            keys={1}
                            menu="Products"
                            subMenus={[
                                { text: 'Haulage', link: '/products/haulage' },
                                { text: 'Warehousing', link: '/products/warehousing' },
                                { text: 'Port Operations', link: '/products/port-operations' },
                                { text: 'Supply Chain Optimization', link: '/products/supply-chain' },
                                { text: 'Raw Materials Shipment', link: '/products/raw-material' },
                            ]}
                            activeIndex={activeSublinkIndex}
                            setActiveNav={() => setActiveSublinkIndex(1)}
                        />
                        <NavItemMobile
                            keys={2}
                            menu="Solutions"
                            subMenus={[
                                { text: 'Real-time Tracking through GPS', link: '/solutions/tracking' },
                                { text: 'Transit Insurance', link: '/solutions/insurance' },
                                { text: 'Expedited Delivery', link: '/solutions/delivery' },
                                { text: 'Reverse Logistics Freight on Delivery', link: '/solutions/reverse-logistics' },
                            ]}
                            activeIndex={activeSublinkIndex}
                            setActiveNav={() => setActiveSublinkIndex(2)}
                        />

                        <NavItemMobile
                            keys={3}
                            menu="Resources"
                            subMenus={[
                                { text: 'Blog', link: '/resources/blog' },
                                { text: 'E-guides', link: '/resources/e-guides' },
                                { text: 'FAQS', link: '/resources/faq' },
                                { text: 'Video', link: '/resources/videos' },
                                { text: 'Glossary', link: '/resources/glossary' },
                                { text: 'Case Studies', link: '/resources/case-studies' },
                            ]}
                            activeIndex={activeSublinkIndex}
                            setActiveNav={() => setActiveSublinkIndex(3)}
                        />

                        <NavItemMobile
                            keys={4}
                            menu="Company"
                            subMenus={[
                                { text: 'About Us', link: '/about' },
                                { text: 'Careers', link: '/careers' },
                                { text: 'Our Impact', link: '/impact' },
                            ]}
                            activeIndex={activeSublinkIndex}
                            setActiveNav={() => setActiveSublinkIndex(4)}
                        />

                        {/* <div className="links" onClick={() => (window.location.href = 'https://haul247.medium.com/')}>
                        <div className="link">Blog</div>
                    </div> */}

                        <div className="button-section">
                            <a href="https://api.whatsapp.com/send?phone=2349010003247" className="contact-no__mobile" target="_blank" rel="noreferrer">
                                <i className="fi fi-brands-whatsapp"></i>
                                <span>09010003247</span>
                            </a>
                            <div onClick={clickContactButton} role="button" tabIndex={0} className="haul247-button-main haul247-button-outline">
                                Speak to an Expert
                            </div>
                            {/* <a href="#contact_sales" className="haul247-button-main haul247-button-outline">
							Contact Us
						</a> */}
                            <a href="https://client.haul247.co/login" className="haul247-button-main">
                                Sign Up
                            </a>
                        </div>
                    </div>
                </div>
            ) : null}

            <div className="call2action">
                <div className="button-section">
                    <a
                        href="https://api.whatsapp.com/send?phone=2349010003247"
                        className="contact-no"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <i
                            className="fi fi-brands-whatsapp"
                            style={{
                                color: 'green',
                                fontSize: '14px',
                            }}
                        ></i>
                        <span>09010003247</span>
                    </a>
                    <div onClick={clickContactButton} role="button" tabIndex={0} className="haul247-button-main haul247-button-outline">
                        Speak to an Expert{' '}
                    </div>
                    <a href="https://client.haul247.co/login" target="_blank" rel="noopener noreferrer" className="haul247-button-main">
                        Sign Up
                    </a>
                </div>

                <div className="country-check" onClick={() => setCountryShow(!countryShow)}>
                    <div className="flag">
                        <img src={NGFLAG} alt="flags" />
                    </div>
                    <p>NG</p>
                    <div className="arrow-down">
                        <i className="fi fi-rr-angle-down"></i>
                    </div>
                </div>

                {countryShow ? (
                    <div className="countryHolder">
                        <div className="flags">
                            <div className="flag">
                                <img src={NGFLAG} alt="flags" />
                            </div>
                            <p>Nigeria</p>

                            <div className="arrow-down checked">
                                <i className="fi fi-rr-check"></i>
                            </div>
                        </div>

                        {/* <div className="flags">
                                <div className="flag br">
                                    <img src={BRFLAG} alt="flags" />
                                </div>
                                <p>Benin</p>
                                <div className="arrow-down">Coming Soon</div>
                            </div> */}

                        <div className="flags">
                            <div className="flag gh">
                                <img src={GHFLAG} alt="flags" />
                            </div>
                            <p>Ghana</p>
                            <div className="arrow-down">Coming Soon</div>
                        </div>

                        {/* <div className="flags">
                                <div className="flag eg">
                                    <img src={EGFLAG} alt="flags" />
                                </div>
                                <p>Egypt</p>
                                <div className="arrow-down">Coming Soon</div>
                            </div> */}
                    </div>
                ) : null}

                <div className="men" onClick={() => setMenu(!menu)}>
                    <img src={BRG} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Header
