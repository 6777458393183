import { createClient } from 'contentful'

class ContentfulClient {
    constructor() {
        this.client = createClient({
            space: 'bb8jztk6njbo',
            accessToken: 'y9rY2R-M5n_GeOtxSnMmMhUEIGrBlrEqDqwOgE_zbNM',
        })
    }

    async getEntries(content_type) {
        try {
            const response = await this.client.getEntries({
                content_type,
            })
            const entries = response.items.map((el) => ({
                ...el.fields,
                image: el?.fields?.image?.fields?.file.url || '',
            }))
            return entries
        } catch (error) {
            // console.error(error)
            return null
        }
    }

    async getEntry(content_type, slug) {
        try {
            const response = await this.client.getEntries({
                content_type,
                'fields.slug': slug,
            })
            const entry = response.items.map((el) => ({
                ...el.fields,
                image: el?.fields?.image?.fields?.file?.url || '',
            }))
            return entry[0]
        } catch (error) {
            // console.error(error)
            return null
        }
    }
}

export default ContentfulClient
