import React from 'react';
import './scrollTop.scss'

const ScrollTop = () => {

    const scrollUp = () => {

        window.scrollTo(0,0)

    }

    return (

        <div className="scrollTop" onClick={ () => scrollUp() }>

            <i className="fi fi-sr-angle-small-up"></i>

        </div>

    );
}

export default ScrollTop;
