import React from 'react'
import TruckIcon from '../../assets/icons/truckYellow.svg'
import BadgeIcon from '../../assets/icons/badge.svg'
import TrackingTruckIcon from '../../assets/icons/trackingTruck.svg'
import TimerIcon from '../../assets/icons/timer.svg'
import RocketIcon from '../../assets/icons/rocket.svg'
import BulbIcon from '../../assets/icons/bulb.svg'
import WarehouseIcon from '../../assets/icons/warehouse.svg'
import PortIcon from '../../assets/icons/port.svg'
import SupplyIcon from '../../assets/icons/supplyChain.svg'
import Hero from '../../components/ui/hero'
import BenefitSection from '../../components/ui/benefitSection'
import Brands from '../../components/ui/brands'
import StepByStep from '../../components/ui/stepByStep'
import Pages from '../../components/ui/pages'
import StatsCount from '../../components/ui/statsCount'
import Faq from '../../components/ui/faq'
import ContactForm from '../../components/ui/contactForm'
import Footer from '../../components/ui/layout/footer'
import Cta from '../../components/ui/cta'
import SEOTag from '../../components/seoTag'

const Haulage = () => {
    const haulageBenefits = [
        {
            heading: 'Swift and Secure',
            subText:
                'Speed and security are our hallmarks. We transport your cargo swiftly while maintaining the highest standards of safety throughout the journey.',
            icon: BadgeIcon,
        },
        {
            heading: 'Real-time Tracking',
            subText:
                'Stay informed every step of the way. Our advanced tracking systems provide real-time updates, offering peace of mind and transparency throughout the transportation process.',
            icon: TrackingTruckIcon,
        },
        {
            heading: 'Efficiency Redefined',
            subText:
                'Our commitment to efficiency isn’t about timely delivery only. It’s about optimizing routes, minimizing costs, and enhancing the overall logistics experience.',
            icon: TimerIcon,
        },
        {
            heading: 'Moving Forward Together, Reliability Unmatched',
            subText: `Trust is the cornerstone of our solutions. We guarantee your goods safety and timely delivery regardless of size or destination. Haul247 makes your cargo our priority from the moment it's entrusted to us.`,
            icon: RocketIcon,
        },
        {
            heading: 'Tailored Solutions',
            subText:
                'We understand that every cargo comes with unique requirements. Our haulage solutions are customized to suit your specific needs, whether temperature-sensitive goods, fragile items, or oversized cargo.',
            icon: BulbIcon,
        },
    ]

    return (
        <>
            <SEOTag
                image=""
                title="Haul247 | Haulage, Warehousing & Port Operations Solutions for Africa |The Supply Chain Logistics Platform"
                url="https://www.haul247.co/products/haulage"
                description="From efficient freight management to fast reliable delivery, trust us to move your cargo safely and on time. Start moving your cargo reliably and fast!"
            />

            <div className="font-inter text-[#242424]">
                <Hero
                    icon={TruckIcon}
                    heading={'Your Cargo’s Journey, Our Commitment'}
                    subHeading={`We are redefining the trajectory of transportation with our Haulage solutions. Seamlessly connecting destinations, our commitment is to haul your cargo swiftly, securely, and with unparalleled precision.`}
                    buttonText={'Efficient Haulage Starts Here'}
                />

                <BenefitSection benefits={haulageBenefits} />

                <Brands />

                <StatsCount
                    stats={[
                        { number: 80, text: 'of Haul247 clients saw an increase in the On-time delivery time of their cargo', active: false },
                        { number: 20, text: 'Decrease in transit expenses, optimizing routes, and minimizing unnecessary expenditures', active: true },
                        { number: 90, text: 'Customer satisfaction index thanks to consistent, reliable, and timely deliveries', active: false },
                    ]}
                />

                <StepByStep />

                <Pages
                    pages={[
                        {
                            title: 'Warehousing',
                            description:
                                'It’s not just about storing goods, it’s about maximizing every inch of space efficiently without compromising accessibility.',
                            link: '/products/warehousing',
                            icon: WarehouseIcon,
                        },
                        {
                            title: 'Port Operations',
                            description:
                                'From documentation to efficient cargo movement, trust us to navigate ports effortlessly. We handle the complexities while you focus on your business growth.',
                            link: '/products/port-operations',
                            icon: PortIcon,
                        },
                        {
                            title: 'Supply Chain Optimization',
                            description: 'We analyze and strategize and implement tailored solutions for efficiency and cost-effectiveness.',
                            link: '/products/supply-chain',
                            icon: SupplyIcon,
                        },
                    ]}
                />
                <div className="font-br-firma">
                    <div className="bg-[#FFFFF7]">
                        <Faq
                            faqs={[
                                {
                                    question: 'What types of goods do you transport?',
                                    answer: `We handle a diverse range of goods, including perishable items, machinery, consumer goods, and more.`,
                                },
                                { question: 'Where do you operate?', answer: `Our services currently span across all 36 states of Nigeria.` },
                                {
                                    question: 'What sizes of cargo can you accommodate?',
                                    answer: `From small parcels to tonnes of large quantities, we have the capacity and expertise to handle various sizes and weights.`,
                                },
                                {
                                    question: `How do you ensure the safety of transported goods?`,
                                    answer: `We prioritize safety through advanced tracking systems, secure packaging, and trained professionals to handle goods with care.`,
                                },
                                {
                                    question: `What transportation methods do you employ?`,
                                    answer: `We currently utilize trucks to move goods from one point to another.`,
                                },
                            ]}
                        />
                    </div>

                    <Cta
                        pillText={'Ready To Haul Your Goods?'}
                        ctaHeading={'Talk to a haulage solutions expert and see the haul247 platform in action.'}
                        ctaButton={'Explore Our Haulage Solutions Today!'}
                    />

                    <ContactForm />

                    <Footer />
                </div>
            </div>
        </>
    )
}

export default Haulage
