import axios from 'axios'
import { BASE_URL } from './config'

const APIService = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
})

export default APIService
