import React, { useEffect, useState } from 'react'
import ContentfulClient from '../../../utils/contentfull/contentfull'
import { useParams } from 'react-router-dom'
import LeftArrow from '../../../assets/icons/left-arrow.svg'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import PostCardSkeleton from './components/postCardSkeleton'
import PostCard from './components/postCard'
import ContactForm from '../../../components/ui/contactForm'
import Footer from '../../../components/ui/layout/footer'
import { contentFormat, documentToReactComponents } from '../../../utils/contentfull/contentfull-text'
import './blogs.scss'

const BlogPost = () => {
    const [post, setPost] = useState(null)
    const [posts, setPosts] = useState([])
    const [loadingSinglePost, setLoadingSinglePost] = useState(true)
    const [loadingPosts, setLoadingPost] = useState(true)
    const { slug } = useParams()

    useEffect(() => {
        const contentful = new ContentfulClient('blog')

        const fetchPosts = async () => {
            const blogs = await contentful.getEntries()
            if (blogs) {
                const filteredPosts = blogs.filter((blog) => blog.slug !== slug)
                const shuffledPosts = filteredPosts.sort(() => 0.5 - Math.random())
                const selectedPosts = shuffledPosts.slice(0, 3)
                setPosts(selectedPosts)
            }
            setLoadingPost(false)
        }

        const fetchData = async () => {
            const post = await contentful.getEntry(slug)
            if (post) {
                setPost(post)
            }
            // console.log(post)
            setLoadingSinglePost(false)
        }
        fetchPosts()
        fetchData()
    }, [slug])

    console.log(post?.content)
    const article = documentToReactComponents(post?.content, contentFormat)

    return (
        <>
            <div className="font-inter text-[#242424] ">
                <section
                    className="body-padding md:pt-[85px] md:pb-[69px] bg-no-repeat bg-cover   text-white h-full min-h-[300px] md:h-[400px] bg-[#222] relative z-10"
                    style={{ backgroundImage: post ? `url(${post?.image})` : 'none' }}
                >
                    <div className=" w-full md:w-[900px] p-6 bg-black bg-opacity-50 absolute inset-0 md:relative">
                        <div className="flex flex-row mb-11">
                            <img src={LeftArrow} alt="icon" />
                            <Link to="/resources/blog" className="ml-2 font-bold text-sm font-inter-md">
                                Back to Blog
                            </Link>
                        </div>
                        <h1 className="font-bold font-inter-bold text-2xl md:text-[38px] md:leading-[48px] ">{post?.title}</h1>
                        <p className=" text-lg md:text-xl mt-3">{post?.subTitle}</p>
                        <p className="text-[#aeaaaa] mt-6 text-sm font-semibold">{dayjs(post?.date).format('MMMM D, YYYY')}</p>
                    </div>
                </section>

                <section id="blog-post" className="body-padding mt-20">
                    {loadingSinglePost ? (
                        <div className="animate-pulse">
                            <div className="md:w-[800px]">
                                <div className="h-10 bg-gray-400 w-full "></div>
                                <div className="h-12 bg-gray-400 w-full mt-5"></div>
                                <div className="h-24 bg-gray-400 w-full mt-5"></div>
                                <div className="h-24 bg-gray-400 w-full mt-5"></div>
                                <div className="h-24 bg-gray-400 w-full mt-5"></div>
                                <div className="h-24 bg-gray-400 w-full mt-5"></div>
                            </div>
                        </div>
                    ) : article ? (
                        <div className="md:w-[800px] list-outside">{article}</div>
                    ) : (
                        <div className=" mx-auto  bg-white shadow-lg rounded-lg p-6 text-center">
                            <p className="text-xl font-bold">Content not available yet</p>
                        </div>
                    )}
                </section>

                <section className="bg-[#FFFFF7] body-padding py-20 mt-20">
                    <div>
                        <h4 className="font-inter-md text-xl ">More amazing articles for you</h4>
                        <div className=" grid sm:grid-cols-[repeat(auto-fill,minmax(386px,1fr))] md:gap-7 mt-12">
                            {loadingPosts
                                ? Array.from({ length: 2 }).map((_, idx) => <PostCardSkeleton key={idx} />)
                                : posts.length
                                  ? posts.map((blog, idx) => <PostCard baseUrl={'/resources/blog/'} width={'386px'} key={idx} post={blog} />)
                                  : null}
                        </div>
                    </div>
                </section>
            </div>

            <div className="font-br-firma">
                <ContactForm />
                <Footer />
            </div>
        </>
    )
}

export default BlogPost
