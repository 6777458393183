import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const NavItems = (props) => {
    return (
        <li className=" block relative nav-items cursor-pointer">
            <Link to="#" className="flex items-center h-20 gap-2">
                <span className="font-medium">{props.menu}</span>
                <span>
                    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5 5.16663C4.73333 5.16663 4.47222 5.11107 4.21667 4.99996C3.96111 4.88885 3.73333 4.73329 3.53333 4.53329L0.133333 1.13329C0.0444444 1.06663 0 0.972181 0 0.849959C0 0.727737 0.0388889 0.627737 0.116667 0.549959C0.194444 0.472181 0.294444 0.433292 0.416667 0.433292C0.538889 0.433292 0.633333 0.477737 0.7 0.566626L4.13333 3.96663C4.35556 4.21107 4.64444 4.33329 5 4.33329C5.35556 4.33329 5.65556 4.21107 5.9 3.96663L9.3 0.533292C9.36667 0.466626 9.46111 0.433292 9.58333 0.433292C9.70556 0.433292 9.80556 0.472181 9.88333 0.549959C9.96111 0.627737 10 0.727737 10 0.849959C10 0.972181 9.95556 1.06663 9.86667 1.13329L6.46667 4.53329C6.26667 4.73329 6.03889 4.88885 5.78333 4.99996C5.52778 5.11107 5.26667 5.16663 5 5.16663Z"
                            fill="#242424"
                        />
                    </svg>
                </span>
            </Link>

            {props.subMenus && (
                <div
                    className={`nav-dropdown bg-white absolute  top-[60px] left-28 transform -translate-x-1/2 w-full max-w-5xl rounded-[0.9375rem] border border-grey-border border-opacity-30 z-[9999]  min-w-[700px] p-7`}
                >
                    <svg width="32" viewBox="0 0 36 17" fill="none" className="absolute top-0 transform -translate-y-full left-[42.7%]">
                        <path
                            d="M14.8493 1.82843C16.4114 0.26633 18.9441 0.26633 20.5062 1.82843L35.3554 16.6777H6.51479e-05L14.8493 1.82843Z"
                            fill="#1C4863"
                        ></path>
                    </svg>
                    <ul className=" grid grid-cols-2">
                        {props.subMenus.map((el, iid) => (
                            <li key={iid} className="hover:bg-[#F7F7F7] p-4">
                                <Link to={el.link} className="flex  gap-5">
                                    <div className="w-[29px] h-[29px]">
                                        <img src={el.icon} alt="icon" />
                                    </div>

                                    <div>
                                        <h6 className="text-sm  font-semibold mb-1">{el.text}</h6>
                                        <span className="text-[#777777] text-xs ">{el.subText}</span>
                                    </div>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </li>
    )
}

export const NavItemSmall = (props) => {
    return (
        <li className=" block relative nav-items cursor-pointer">
            <Link to="#" className="flex items-center h-20 gap-2">
                <span className="font-medium">{props.menu}</span>
                <span>
                    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5 5.16663C4.73333 5.16663 4.47222 5.11107 4.21667 4.99996C3.96111 4.88885 3.73333 4.73329 3.53333 4.53329L0.133333 1.13329C0.0444444 1.06663 0 0.972181 0 0.849959C0 0.727737 0.0388889 0.627737 0.116667 0.549959C0.194444 0.472181 0.294444 0.433292 0.416667 0.433292C0.538889 0.433292 0.633333 0.477737 0.7 0.566626L4.13333 3.96663C4.35556 4.21107 4.64444 4.33329 5 4.33329C5.35556 4.33329 5.65556 4.21107 5.9 3.96663L9.3 0.533292C9.36667 0.466626 9.46111 0.433292 9.58333 0.433292C9.70556 0.433292 9.80556 0.472181 9.88333 0.549959C9.96111 0.627737 10 0.727737 10 0.849959C10 0.972181 9.95556 1.06663 9.86667 1.13329L6.46667 4.53329C6.26667 4.73329 6.03889 4.88885 5.78333 4.99996C5.52778 5.11107 5.26667 5.16663 5 5.16663Z"
                            fill="#242424"
                        />
                    </svg>
                </span>
            </Link>

            <div
                className={`nav-dropdown bg-white absolute  top-[60px] left-20 transform -translate-x-1/2 w-full max-w-5xl rounded-[0.9375rem] border border-grey-border border-opacity-30 z-[9999]  min-w-[180px] p-5`}
            >
                <svg width="32" viewBox="0 0 36 17" fill="none" className="absolute top-0 transform -translate-y-full left-[42.7%]">
                    <path
                        d="M14.8493 1.82843C16.4114 0.26633 18.9441 0.26633 20.5062 1.82843L35.3554 16.6777H6.51479e-05L14.8493 1.82843Z"
                        fill="#1C4863"
                    ></path>
                </svg>
                <ul className="space-y-1">
                    {props.subMenus.map((el, idx) => (
                        <li key={idx} className="hover:bg-[#F7F7F7] p-2">
                            <Link to={el.link} className="flex items-center  h-full  gap-5">
                                {el.icon && (
                                    <div className="w-[29px] h-[29px]">
                                        <img src={el.icon} alt="icon" className="w-full h-full" />
                                    </div>
                                )}

                                <h6 className="text-sm  font-semibold">{el.text}</h6>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </li>
    )
}

export const NavItemMobile = (props) => {
    let navigate = useNavigate()

    function handleToggle() {
        if (props.activeIndex === props.keys) {
            props.setActiveNav(null)
        } else {
            props.setActiveNav(props.keys)
        }
    }
    return (
        <div className="links" onClick={() => (props.subMenus?.length ? handleToggle() : null)}>
            <div className="link product">{props.menu}</div>

            {props.subMenus?.length > 0 && props.activeIndex === props.keys ? (
                <div className="sublinks">
                    {props.subMenus.map((el, idx) => (
                        <div
                            key={idx}
                            className="sublink"
                            onClick={() => {
                                props.setActiveNav(null)
                                navigate(el.link)
                            }}
                        >
                            <p>{el.text}</p>
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    )
}

export default NavItems
