import React, { useEffect, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import ContactForm from '../../../components/ui/contactForm'
import Footer from '../../../components/ui/layout/footer'
import ContentfulClient from '../../../utils/contentfull/contentfull-v2.js'
import shippingImg from '../../../assets/images/shipping-car.png'
import sunImg from '../../../assets/images/sun.png'

const contentful = new ContentfulClient()
const Glossary = () => {
    const [loading, setLoading] = useState(true)
    const [glossaries, setGlossaries] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [toggleSearch, setToggleSearch] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const glossaryNames = [
                'glossaryA',
                'glossaryB',
                'glossaryC',
                'glossaryD',
                'glossaryE',
                'glossaryF',
                'glossaryG',
                'glossaryH',
                'glossaryI',
                'glossaryJ',
                'glossaryK',
                'glossaryL',
                'glossaryM',
                'glossaryN',
                'glossaryO',
                'glossaryP',
                'glossaryQ',
                'glossaryR',
                'glossaryS',
                'glossaryT',
                'glossaryU',
                // 'glossaryV',
                // 'glossaryW',
                // 'glossaryX',
                // 'glossaryY',
                // 'glossaryZ',
            ]
            const promises = glossaryNames.map((name) => contentful.getEntries(name))
            const results = await Promise.allSettled(promises)
            const glossaries = []

            glossaryNames.forEach((name, index) => {
                glossaries.push({
                    name: name,
                    entries: results[index].status === 'fulfilled' ? results[index].value : [],
                    contentType: name.slice(-1),
                })
            })

            // console.log('glossaries', glossaries)
            setGlossaries(glossaries)
            setLoading(false)
        }
        fetchData()
    }, [])

    const filteredGlossaries = useMemo(() => {
        let result = [...glossaries]
        if (!searchTerm) return result

        // Flatten all entries across all glossaries into a single array
        const allEntries = glossaries.flatMap((glossary) => {
            if (!glossary.entries) return []
            const result = glossary?.entries?.map((entry) => ({
                contentType: glossary.contentType,
                name: glossary.name,
                subject: entry.subject,
                slug: entry.slug,
            }))
            return result
        })

        const filteredEntries = allEntries.filter((entry) => entry.subject.toLowerCase().includes(searchTerm.toLowerCase()))
        return filteredEntries
    }, [glossaries, searchTerm])

    return (
        <>
            <div className="font-inter text-[#242424]">
                <section className="bg-[#1C4863] w-full body-padding py-16 md:pt-[88px] md:pb-[100px]">
                    <div className="flex gap-14 relative ">
                        <div className="lg:w-1/2">
                            <h1 className="font-semibold text-3xl md:text-[38px] md:leading-[48px] text-white mb-[30px] mt-[18px] font-inter-md">
                                Become conversant with Logistic Terminologies
                            </h1>
                            <div className="my-4">
                                <label htmlFor="" className="text-white">
                                    Begin by entering the word
                                </label>
                                <div className=" mt-2 flex flex-col md:flex-row w-full  gap-3">
                                    <div className="rounded-md bg-white py-4 px-4 md:px-8 md:w-[500px] flex">
                                        <input
                                            value={searchTerm}
                                            required
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            type="search"
                                            placeholder="Search term or Phrase"
                                            className="border-none focus:outline-none focus:border-none w-full h-full text-black text-base md:text-sm"
                                        />
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setSearchTerm('')
                                            setToggleSearch(!toggleSearch)
                                        }}
                                        className="text-sm md:w-[153px] shrink-0 rounded-[5px] px-[26px] py-[13px] text-white bg-gradient-to-r from-[#f0aa22] to-[#f05822]  font-semibold"
                                    >
                                        {searchTerm ? 'Clear' : 'Search'}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className=" hidden lg:block lg:w-1/2 relative">
                            <div className="absolute -top-16  right-32">
                                <img src={sunImg} alt="" />
                            </div>
                            <div className="absolute top-20">
                                <img src={shippingImg} alt="" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="body-padding mt-10 md:mt-[100px] mb-20">
                    <div>
                        {loading ? (
                            <div className="animate-pulse">
                                <div className="grid repeat(auto-fill,minmax(300px,1fr))] lg:grid-cols-[repeat(auto-fill,minmax(392px,1fr))] gap-5">
                                    {[...Array(20)].map((_, index) => (
                                        <div key={index} className="h-20 bg-gray-200 rounded"></div>
                                    ))}
                                </div>
                            </div>
                        ) : searchTerm ? (
                            <div>
                                {filteredGlossaries?.length > 0 ? (
                                    <div className="grid md:grid-cols-[repeat(auto-fill,minmax(300px,1fr))] lg:grid-cols-[repeat(auto-fill,minmax(392px,1fr))]  w-full mt-[30px] gap-5">
                                        {filteredGlossaries?.map((glossary, idx) => (
                                            <Link
                                                key={idx}
                                                to={`/resources/glossary/${glossary.name}/${glossary.slug}?contentType=${glossary.contentType}`}
                                                className="h-20 rounded-[5px] text-sm bg-[#FAFAF7] flex items-center px-10 shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
                                            >
                                                <p>{glossary.subject}</p>
                                            </Link>
                                        ))}
                                    </div>
                                ) : (
                                    <p className="text-center">No results found....</p>
                                )}
                            </div>
                        ) : glossaries?.length > 0 ? (
                            glossaries?.map((glossary, index) => (
                                <div key={index} className="mb-20">
                                    <h4 className="font-bold text-2xl md:text-[34px] md:leading-[34px] uppercase">{glossary.contentType}</h4>
                                    <div className="grid md:grid-cols-[repeat(auto-fill,minmax(300px,1fr))] lg:grid-cols-[repeat(auto-fill,minmax(392px,1fr))] w-full mt-[30px] gap-5">
                                        {glossary.entries &&
                                            glossary.entries.map((entry, entryIndex) => (
                                                <Link
                                                    key={entryIndex}
                                                    to={`/resources/glossary/${glossary.name}/${entry.slug}?contentType=${glossary.contentType}`}
                                                    className="h-20 rounded-[5px] text-sm bg-[#FAFAF7]  flex items-center px-10 shadow-sm hover:shadow-lg transition-all duration-300 ease-in-out"
                                                >
                                                    <p>{entry.subject}</p>
                                                </Link>
                                            ))}
                                    </div>
                                </div>
                            ))
                        ) : null}
                    </div>
                </section>
            </div>

            <div className="font-br-firma">
                <ContactForm />
                <Footer />
            </div>
        </>
    )
}

export default Glossary
