import React, {useState, useEffect} from "react";
import "./careers.scss";
// import CareerImage from "../../assets/images/haul247_careers.png";
import health from "../../assets/svg/health.svg";
import remote from "../../assets/svg/remote.svg";
import bond from "../../assets/svg/bond.svg";
import hour from "../../assets/svg/hour.svg";
import pay from "../../assets/svg/pay.svg";
import whoWe from "../../assets/svg/who_we_are.png";
import sect from "../../assets/svg/sect.svg";
import evolve from "../../assets/svg/evolveHand.png";

export default function Careers() {
	const [showMod] = useState(false);

	useEffect(() => {}, [showMod]);

	const careerShow = () => {
		const opo = document.querySelector(".careerOpenings");
		opo.classList.add("openLog");

		setTimeout(() => {
			opo.classList.remove("openLog");
		}, 3000);
	};

	return (
		<div className="careers">
			<div className="careerOpenings">
				<i class="fi fi-sr-sad"></i>
				<p>Sorry, We have no current opening.</p>
			</div>

			<div className="career">
				<div className="textPoint fullpad">
					<h1>Join our exceptional team in defining the future of haulage logistics in Africa.</h1>
					<p>
						At Haul247, we can only achieve our vision of becoming Africa's leading digital-based
						logistics company when we have exceptional talents like you onboard with us without bias
						or discrimination with respect to race, gender, background or disability.
					</p>
					{/* <a
						href="https://haul247.bamboohr.com/careers"
						className="careers--button first"
						target="_blank"
						rel="noreferrer"
					>
						See current openings
					</a> */}
					<div className="careers--button first" onClick={() => careerShow()}>
						See current openings
					</div>
				</div>

				{/* <div className="career_image">
					<img src={CareerImage} alt="haul247 careers" />
				</div> */}
			</div>

			<div className="whatDefinesUs fullpad">
				<div className="photoPinch">
					<img src={whoWe} alt="Who we are?" />
				</div>

				<div className="textSession">
					<div className="title">What Defines Us?</div>

					<div className="heading">
						<h1>Let’s define the logistics sphere in africa together.</h1>

						<div className="talk_topic">
							Our core values define the Haul247 culture and forms the foundation of our identity
							and the compass of our interaction with all stakeholders: Clients, investors, partners
							and our communities.
						</div>

						<div className="list_career">
							<div className="list">
								<div className="icony">
									<i className="fi fi-rr-check"></i>
								</div>
								<p>Customer centric</p>
							</div>

							<div className="list">
								<div className="icony">
									<i className="fi fi-rr-check"></i>
								</div>
								<p>Integrity</p>
							</div>

							<div className="list">
								<div className="icony">
									<i className="fi fi-rr-check"></i>
								</div>
								<p> Value </p>
							</div>

							<div className="list">
								<div className="icony">
									<i className="fi fi-rr-check"></i>
								</div>
								<p> Ownership </p>
							</div>

							<div className="list">
								<div className="icony">
									<i className="fi fi-rr-check"></i>
								</div>
								<p> Safety </p>
							</div>

							<div className="list">
								<div className="icony">
									<i className="fi fi-rr-check"></i>
								</div>
								<p> Collaboration and Teamwork </p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="perks_section fullpad">
				<h1>
					Have a look at some of the perks our team members enjoy asides a competitive salary.
				</h1>

				<div className="perks">
					<div className="perk">
						<div className="perked">
							<img src={health} alt="haul247_perks" />
							<p>Health Insurance</p>
						</div>

						<div className="textOn">
							We provide a comprehensive health insurance to cover the medical needs of you and your
							dependents.🏥
						</div>
					</div>

					<div className="perk">
						<div className="perked">
							<img src={remote} alt="haul247_perks" />
							<p>Remote Friendly</p>
						</div>

						<div className="textOn">
							Not every time traffic hassle here and there, enjoy the comfort of working from your
							space.🧑‍💻
						</div>
					</div>

					<div className="perk">
						<div className="perked">
							<img src={bond} alt="haul247_perks" />
							<p>Team bonding</p>
						</div>

						<div className="textOn">
							You can boast of having the best on your side, we definitely win together and move
							ahead as we hold one another's hands.👫
						</div>
					</div>

					<div className="perk">
						<div className="perked">
							<img src={hour} alt="haul247_perks" />

							<p>Flexible working hour</p>
						</div>

						<div className="textOn">
							Your efficiency matters to us. We don't want you under the weather on our account 😉
						</div>
					</div>

					<div className="perk">
						<div className="perked">
							<img src={pay} alt="haul247_perks" />
							<p>Paid annual leave</p>
						</div>

						<div className="textOn">
							All team members get a certain number of days of paid annual leave.🧳
						</div>
					</div>
				</div>
			</div>

			<div className="whatDefinesUs fullpad fitting">
				<div className="textSession">
					<div className="title">Our Fitting</div>

					<div className="heading">
						<h1>Find where you fit</h1>

						<div className="talk_topic">
							Haul247 is made up of diverse teams all working to create endless opportunities for
							the thousands of people and businesses using our products and services. Join a team
							and inspire the work
						</div>

						<div className="list_career">
							<div className="list">
								<div className="icony">
									<i className="fi fi-rr-check"></i>
								</div>
								<p>Engineering</p>
							</div>

							<div className="list">
								<div className="icony">
									<i className="fi fi-rr-check"></i>
								</div>
								<p>Legal and compliance</p>
							</div>

							<div className="list">
								<div className="icony">
									<i className="fi fi-rr-check"></i>
								</div>
								<p> Product </p>
							</div>

							<div className="list">
								<div className="icony">
									<i className="fi fi-rr-check"></i>
								</div>
								<p> Logistics Operations </p>
							</div>

							<div className="list">
								<div className="icony">
									<i className="fi fi-rr-check"></i>
								</div>
								<p> Warehousing Operations </p>
							</div>

							<div className="list">
								<div className="icony">
									<i className="fi fi-rr-check"></i>
								</div>
								<p> Data Analyst </p>
							</div>

							<div className="list">
								<div className="icony">
									<i className="fi fi-rr-check"></i>
								</div>
								<p> Marketing </p>
							</div>

							<div className="list">
								<div className="icony">
									<i className="fi fi-rr-check"></i>
								</div>
								<p> Customer Experience </p>
							</div>

							<div className="list">
								<div className="icony">
									<i className="fi fi-rr-check"></i>
								</div>
								<p> Finance and business operations </p>
							</div>
						</div>
					</div>
				</div>

				<div className="photoPinch pinch">
					<img src={sect} alt="Who we are?" />
				</div>
			</div>

			<div className="bond fullpad">
				<div className="paper">
					<h1>Because we give room for evolving, join us as:</h1>

					<li>An experienced hire with required skills-set</li>
					<li>An intern open to learning with possibilities of becoming a full staff.</li>

					<div className="careers--button" onClick={() => careerShow()}>
						See current openings
					</div>

					{/* <a
						href="https://haul247.bamboohr.com/careers"
						className="careers--button "
						target="_blank"
						rel="noreferrer"
					>
						See current openings
					</a> */}
					<div className="imagePin">
						<img src={evolve} alt="evolve" />
					</div>
				</div>
			</div>
		</div>
	);
}
