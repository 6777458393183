import React from 'react'
import Hero from '../../components/ui/hero'
import DeliveryCarrIcon from '../../assets/icons/delivery-car.svg'
import BenefitSection from '../../components/ui/benefitSection'
import SpeedometerIcon from '../../assets/icons/benefits/speedo.svg'
import CycleIcon from '../../assets/icons/benefits/cycle.svg'
import DeliverIcon from '../../assets/icons/benefits/deliver.svg'
import Faq from '../../components/ui/faq'
import ContactForm from '../../components/ui/contactForm'
import Footer from '../../components/ui/layout/footer'
import Testimonial from '../../components/ui/testimonial'
import Cta from '../../components/ui/cta'
import SEOTag from '../../components/seoTag'

const Delivery = () => {
    return (
        <>
            <SEOTag image="" title="Haul247 | Expedited Delivery" url="https://www.haul247.co/solutions/delivery" description="" />
            <div className="font-inter text-[#242424]">
                <Hero
                    icon={DeliveryCarrIcon}
                    heading={'Accelerating your Logistics to Success'}
                    subHeading={`Our expedited delivery solution isn’t just about speed; they're about reliability, precision, and meeting your critical deadlines with unwavering efficiency`}
                />

                <section className="mb-20">
                    <BenefitSection
                        benefits={[
                            {
                                heading: 'Speed Beyond Expectations',
                                subText: `Time is crucial. Our expedited delivery services promise swift solutions for your urgent shipments. From same-day to express options, we guarantee rapid and dependable delivery, meeting your critical timelines.`,
                                icon: SpeedometerIcon,
                            },
                            {
                                heading: 'Tailored Solutions for Your Urgency',
                                subText: `Your needs are unique. That's why we offer customizable delivery options. Whether it's air freight, dedicated ground transport, or specialized handling, our expedited services are tailored to suit your specific requirements.`,
                                icon: CycleIcon,
                            },
                            {
                                heading: 'Reliable Delivery, No Compromises',
                                subText: `Speed doesn't mean sacrificing safety. With our expedited services, your goods are delivered securely and intact, ensuring they arrive at their destination in pristine condition.`,
                                icon: DeliverIcon,
                            },
                        ]}
                    />
                </section>

                <Testimonial
                    backgroundColor={`bg-[#FAFAF7]`}
                    role={`Jibxie-ONC Concepts Logistics`}
                    writer={`Femi Jibowo`}
                    message={`Haul247 makes it easy to generate revenue for our idle assets. My company has a few warehouses in various locations in states outside Lagos with minimal exposure. Partnering with Haul247 has enabled us activate and increase usage of our warehouses, we can see orders on our app and effectively meet those requests.`}
                />
            </div>
            <div className="font-br-firma mt-8">
                <div className="bg-[#FFFFF7]">
                    <Faq
                        faqs={[
                            {
                                question: 'How is the delivery timeframe determined for expedited shipments?',
                                answer: `The delivery time frame for expedited shipments depends on various factors such as distance, transportation mode, package size, and specific service chosen. Our team will work closely with you to determine the most suitable and fastest delivery option.`,
                            },
                            {
                                question: 'Can I track my expedited shipment in real-time?',
                                answer: `Absolutely. Our expedited delivery services include real-time tracking capabilities, allowing you to monitor the progress of your shipments at every stage and ensuring transparency and peace of mind.`,
                            },
                            {
                                question: 'How can I request expedited delivery services for my shipments?',
                                answer: `Initiating expedited delivery services is easy. Simply get in touch with our team, and we&#39;ll guide you through the process, explaining available options and assisting in selecting the most suitable expedited service for your needs.`,
                            },
                            {
                                question: `How does the cost of expedited delivery compare to standard shipping?`,
                                answer: `Expedited delivery typically incurs higher costs compared to standard shipping due to the accelerated speed and specialized handling involved. However, the benefits of meeting urgent deadlines often outweigh the additional expense.`,
                            },
                        ]}
                    />
                </div>

                <Cta
                    pillText={'Ready To Meet Your Urgent Deadlines?'}
                    ctaHeading={`Experience unmatched speed with expedited delivery.`}
                    ctaButton={`Request Quote`}
                    headingWidth={'lg:w-[703px]'}
                />

                <ContactForm />

                <Footer />
            </div>
        </>
    )
}

export default Delivery
