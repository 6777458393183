import React, { useState, useEffect } from 'react'
import PostCard from '../blogs/components/postCard'
import PostCardSkeleton from '../blogs/components/postCardSkeleton'
import ContactForm from '../../../components/ui/contactForm'
import Footer from '../../../components/ui/layout/footer'
import ContentfulClient from '../../../utils/contentfull/contentfull'

const EGuide = () => {
    const [loading, setLoading] = useState(true)
    const [eGuides, setEGuides] = useState([])

    useEffect(() => {
        const contentful = new ContentfulClient('eGuide')
        const fetchData = async () => {
            const guides = await contentful.getEntries()
            if (guides) {
                console.log(guides)
                setEGuides(guides)
            }
            setLoading(false)
        }
        fetchData()
    }, [])
    return (
        <>
            <section className="bg-white font-inter text-[#242424] body-padding">
                <h1 className="font-semibold text-2xl md:text-[40px] md:leading-[50px] my-12">Haul247 E-guides</h1>
                <div></div>
                <div className="grid sm:grid-cols-[repeat(auto-fill,minmax(386px,1fr))] gap-7">
                    {loading
                        ? Array.from({ length: 3 }).map((_, idx) => <PostCardSkeleton key={idx} />)
                        : eGuides.length
                          ? eGuides.map((blog, idx) => <PostCard buttonText="Get Now" baseUrl={'/resources/e-guides/'} key={idx} post={blog} />)
                          : null}
                </div>
            </section>

            <div className="font-br-firma">
                <ContactForm />
                <Footer />
            </div>
        </>
    )
}

export default EGuide
