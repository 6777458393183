import React from 'react'
import PortIcon from '../../assets/icons/port.svg'
import SupplyIcon from '../../assets/icons/supplyChain.svg'
import Hero from '../../components/ui/hero'
import BenefitSection from '../../components/ui/benefitSection'
import Brands from '../../components/ui/brands'
import Pages from '../../components/ui/pages'
import StatsCount from '../../components/ui/statsCount'
import TruckIcon from '../../assets/icons/benefits/trucks.svg'
import ShipIcon from '../../assets/icons/benefits/ships.svg'
import HookIcon from '../../assets/icons/benefits/hook.svg'
import PointerIcon from '../../assets/icons/benefits/pointer.svg'
import DockerIcon from '../../assets/icons/benefits/docker.svg'
import CustomIcon from '../../assets/icons/benefits/custom.svg'
import SupportIcon from '../../assets/icons/benefits/support.svg'
import WarehouseIcon from '../../assets/icons/warehouse.svg'
import Faq from '../../components/ui/faq'
import ContactForm from '../../components/ui/contactForm'
import Footer from '../../components/ui/layout/footer'
import Cta from '../../components/ui/cta'
import SEOTag from '../../components/seoTag'

const PortOperations = () => {
    return (
        <>
            <SEOTag
                image=""
                title="Haul247 | Port Operations | Clearing and Forwarding| Freight Forwarding "
                url="https://www.haul247.co/products/port-operations"
                description=""
            />

            <div className="font-inter text-[#242424]">
                <Hero
                    icon={PortIcon}
                    heading={'Smooth Sailing Logistics'}
                    subHeading={`Haul247 Port Operations is designed to navigate the complexities of port logistics and its intricacies, ensuring smooth, efficient, and hassle-free handling of your goods at every port of call.`}
                    buttonText={'Navigate Ports Effortlessly!'}
                    link={'/contact'}
                />

                <BenefitSection
                    benefits={[
                        {
                            heading: 'Smooth Sailing Through Ports',
                            subText: `Trust us to handle the complexities. We ensure your cargo maneuvers through ports effortlessly, minimizing delays and optimizing transit times.`,
                            icon: ShipIcon,
                        },
                        {
                            heading: 'Expertise at Every Port',
                            subText: `Our knowledge and experience at various ports globally enable us to provide consistent, reliable services, no matter where your cargo needs to go.`,
                            icon: HookIcon,
                        },
                        {
                            heading: 'End-to-End Coordination',
                            subText: `We don’t just stop at the port gates. Our services encompass seamless coordination with inland transportation, ensuring a continuous logistics chain.`,
                            icon: PointerIcon,
                        },
                        {
                            heading: 'Effortless Handling',
                            subText: `From documentation to cargo handling, our seasoned professionals orchestrate a seamless process, ensuring your goods move swiftly through the port.`,
                            icon: DockerIcon,
                        },
                        {
                            heading: 'Customs Expertise',
                            subText: `Navigating customs procedures can be daunting. We streamline this process, minimizing delays and ensuring compliance, guaranteeing a hassle-free transition for your cargo.`,
                            icon: CustomIcon,
                        },
                        {
                            heading: 'Comprehensive Support',
                            subText: `Our support extends beyond the port. We provide end-to-end solutions, coordinating inland transportation and further logistics to ensure a cohesive journey for your goods.`,
                            icon: SupportIcon,
                        },
                    ]}
                />

                <Brands />

                <StatsCount
                    grid={'md:grid-cols-4'}
                    textWidth={'w-[214px]'}
                    stats={[
                        { number: 30, text: `Decrease in cargo processing times, ensuring swift movements through ports.`, active: false },
                        { number: 90, text: `Accuracy rate in documentation and cargo handling, guaranteeing seamless operations.`, active: true },
                        { number: 20, text: `Decrease in transit times tthrough their optimized port operations.`, active: false },
                        { number: 15, text: `Reduction in overall logistics costs due to their efficient port handling strategies.`, active: false },
                    ]}
                />

                <Pages
                    pages={[
                        {
                            title: 'Supply Chain Optimization',
                            description: 'We analyze and strategize and implement tailored solutions for efficiency and cost-effectiveness.',
                            link: '/products/supply-chain',
                            icon: SupplyIcon,
                        },
                        {
                            title: 'Haulage Solutions',
                            description: `Trust us to deliver your cargo swiftly, securely, and with precision, ensuring it reaches its destination on time, every time.`,
                            link: '/products/haulage',
                            icon: TruckIcon,
                        },
                        {
                            title: 'Warehousing',
                            description:
                                'It’s not just about storing goods, it’s about maximizing every inch of space efficiently without compromising accessibility.',
                            link: '/products/warehousing',
                            icon: WarehouseIcon,
                        },
                    ]}
                />

                <div className="font-br-firma">
                    <div className="bg-[#FFFFF7]">
                        <Faq
                            faqs={[
                                {
                                    question: 'Ready to sail smoothly through ports?',
                                    answer: `Our Port Operations simplifIes your logistics and elevates your cargo transit experience.`,
                                },
                            ]}
                        />
                    </div>

                    <Cta
                        pillText={'Ready to sail smoothly through ports?'}
                        ctaHeading={'Our port operations simplifIes your logistics and elevates your cargo transit experience.'}
                        ctaButton={'Experience Optimized Warehousing Solutions Today!'}
                        headingWidth={'lg:w-[900px]'}
                    />

                    <ContactForm />

                    <Footer />
                </div>
            </div>
        </>
    )
}

export default PortOperations
