import React from 'react'
import YouTube from 'react-youtube'
import { useParams } from 'react-router'
import videos from '../../../data/videos.json'
import ContactForm from '../../../components/ui/contactForm'
import Footer from '../../../components/ui/layout/footer'
import { useMediaQuery } from 'usehooks-ts'
import LeftArrow from '../../../assets/icons/left-arrow.svg'
import { Link } from 'react-router-dom'

const Video = () => {
    const params = useParams()
    const post = videos.posts.find((dataItem) => dataItem.id === params.id)
    const youtubeID = post.url.split('v=')[1]
    const matches = useMediaQuery('(min-width: 768px)')

    return (
        <>
            <section className="body-padding bg-[#1C4863] py-5 md:py-10 text-white">
                <div className=" w-full ">
                    <div className="flex flex-row ">
                        <img src={LeftArrow} alt="icon" />
                        <Link to="/resources/videos" className="ml-2 font-bold text-base font-inter-md">
                            Back to Videos
                        </Link>
                    </div>
                </div>
            </section>
            <section className="bg-white font-inter text-[#242424] body-padding pb-20">
                <div className="flex flex-col justify-center w-full mt-10">
                    <div className="mx-auto bg-slate-200 ">
                        <YouTube
                            videoId={youtubeID}
                            opts={{
                                height: matches ? '390' : '190',
                                width: matches ? '640' : '325',
                            }}
                        />
                    </div>
                    <h3 className="font-semibold font-inter-md text-2xl mt-6 ">{post.title}</h3>
                    <p className="text-[#777777] text-[15px] leading-[25px] my-4">{post.content}</p>
                </div>
            </section>
            <div className="font-br-firma ">
                <ContactForm />
                <Footer />
            </div>
        </>
    )
}

export default Video
