import React, { useState } from 'react'
import CONTACT from '../../assets/images/contact.jpg'
import APIService from '../../utils/http'

const ContactForm = () => {
    const [loading, setLoading] = useState(false)
    const submitForm = async (event) => {
        setLoading(true)
        event.preventDefault()
        const form = document.querySelector('.contactForm')
        const formData = new FormData(event.target)
        const data = Object.fromEntries(formData)

        try {
            await APIService.post('/contact', data)
        } catch (error) {}

        window.dataLayer.push({
            event: 'contact_form_submitted',
        })
        setLoading(false)
        form.reset()
    }
    return (
        <div id="contact_sales" className="contactPage fullpad pad-3x">
            <div className="photoOp">
                <img src={CONTACT} alt="person" />
            </div>

            <div className="contact">
                <div className="tiny">If you need help or have a question, we’re here for you</div>

                <div className="mainText">Get in touch with us for more information.</div>

                <form className="contactForm" onSubmit={submitForm}>
                    <div className="fields">
                        <div className="field">
                            <label> Firstname </label>
                            <input name="first_name" type="text" placeholder="Enter Name" required autoComplete="none" />
                        </div>

                        <div className="field">
                            <label> Lastname </label>
                            <input name="last_name" type="text" placeholder="Enter Name" required autoComplete="none" />
                        </div>
                    </div>

                    <div className="fields">
                        <div className="field">
                            <label> Email Address </label>
                            <input name="email" type="email" placeholder="@" required autoComplete="none" />
                        </div>

                        <div className="field">
                            <label> Phone </label>
                            <input name="phone_number" type="number" placeholder="+234" required min={11} autoComplete="none" />
                        </div>
                    </div>

                    <div className="fields">
                        <div className="field width-100">
                            <label> Your Message </label>
                            <textarea name="message" id="" placeholder="Write your message..." required></textarea>
                        </div>
                    </div>

                    <button type="submit" className="button_send_message">
                        {loading ? <span className="loading-circle   "></span> : <span>Send Message</span>}
                    </button>
                </form>
            </div>
        </div>
    )
}

export default ContactForm
