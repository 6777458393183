import React from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'sonner'
import LeftArrow from '../../../assets/icons/left-arrow.svg'
import { Link } from 'react-router-dom'
import PostCardSkeleton from '../blogs/components/postCardSkeleton'
import PostCard from '../blogs/components/postCard'
import ContactForm from '../../../components/ui/contactForm'
import Footer from '../../../components/ui/layout/footer'
import ContentfulClient from '../../../utils/contentfull/contentfull'
import { contentFormat, documentToReactComponents } from '../../../utils/contentfull/contentfull-text'
import './guide.scss'

const Guide = () => {
    const { slug } = useParams()
    const [loadingGuide, setLoadingGuide] = useState(true)
    const [showLoading, setShowLoading] = useState(true)

    const [singleGuide, setSingleGuide] = useState(null)
    const [guides, setGuides] = useState([])

    useEffect(() => {
        const contentful = new ContentfulClient('eGuide')

        const fetchGuides = async () => {
            const guides = await contentful.getEntries()
            if (guides) {
                const filtered = guides.filter((g) => g.slug !== slug).sort(() => 0.5 - Math.random())
                const selectedGuides = filtered.slice(0, 3)
                setGuides(selectedGuides)
            }
            setShowLoading(false)
        }

        const fetchData = async () => {
            const guide = await contentful.getEntry(slug)
            if (guide) {
                setSingleGuide(guide)
            }
            console.log(guide)
            setLoadingGuide(false)
        }
        fetchGuides()
        fetchData()
    }, [slug])

    const article = documentToReactComponents(singleGuide?.content, contentFormat)

    function submitGuideForm(e) {
        e.preventDefault()
        console.log('submitted')
        toast.success('Guide sent successfully to your email address')
    }

    function handleGetGuide() {
        const contactSection = document.getElementById('guideForm')
        contactSection.scrollIntoView({ behavior: 'smooth' })
    }
    return (
        <>
            <div className="font-inter text-[#242424] ">
                <section
                    className="body-padding md:pt-[85px] md:pb-[69px] bg-no-repeat bg-cover   text-white h-full min-h-[500px] bg-[#222] relative z-10"
                    style={{ backgroundImage: singleGuide ? `url(${singleGuide?.image})` : 'none' }}
                >
                    <div className=" w-full md:w-[900px] p-6 bg-black bg-opacity-50 absolute inset-0 md:relative">
                        <div className="flex flex-row mb-11">
                            <img src={LeftArrow} alt="icon" />
                            <Link to="/resources/e-guides" className="ml-2 font-bold text-sm font-inter-md">
                                Back to E-Guides
                            </Link>
                        </div>
                        <h1 className="font-bold font-inter-bold text-2xl md:text-[38px] md:leading-[48px] ">{singleGuide?.title}</h1>
                        <p className="text-base mt-3 mb-7">{singleGuide?.subTitle}</p>
                        <button
                            onClick={handleGetGuide}
                            className="text-sm rounded-[5px] px-[26px] py-[13px] text-white bg-gradient-to-r from-[#f0aa22] to-[#f05822]  font-semibold"
                        >
                            Get Free Guide
                        </button>
                    </div>
                </section>

                <section id="blog-post" className="body-padding mt-20">
                    <div className=" grid gap-10 xl:grid-cols-[_minmax(750px,_20%)_1fr]">
                        <div>
                            {loadingGuide ? (
                                <div className="animate-pulse">
                                    <div>
                                        <div className="h-10 bg-gray-400 w-full "></div>
                                        <div className="h-12 bg-gray-400 w-full mt-5"></div>
                                        <div className="h-24 bg-gray-400 w-full mt-5"></div>
                                        <div className="h-24 bg-gray-400 w-full mt-5"></div>
                                        <div className="h-24 bg-gray-400 w-full mt-5"></div>
                                        <div className="h-24 bg-gray-400 w-full mt-5"></div>
                                    </div>
                                </div>
                            ) : article ? (
                                <div>{article}</div>
                            ) : (
                                <div className=" mx-auto  bg-white shadow-lg rounded-lg p-6 text-center">
                                    <p className="text-xl font-bold">Guide not available yet</p>
                                </div>
                            )}
                        </div>

                        <form id="guideForm" className=" shadow-md px-6  md:px-10 py-10 rounded-[5px]" onSubmit={submitGuideForm}>
                            <h4 className="font-inter-md font-bold mb-10">Get a copy using this form</h4>
                            <div className="mb-5">
                                <label htmlFor="first_name" className="block mb-[10px] text-sm font-medium text-gray-900 dark:text-white">
                                    First name
                                </label>
                                <input
                                    type="text"
                                    id="first_name"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[5px] focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Enter first name"
                                    required
                                />
                            </div>
                            <div className="mb-5">
                                <label htmlFor="last_name" className="block mb-[10px] text-sm font-medium text-gray-900 dark:text-white">
                                    Last name
                                </label>
                                <input
                                    type="text"
                                    id="last_name"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[5px] focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Enter last name"
                                    required
                                />
                            </div>

                            <div className="mb-5">
                                <label htmlFor="company" className="block mb-[10px] text-sm font-medium text-gray-900 dark:text-white">
                                    Company
                                </label>
                                <input
                                    type="text"
                                    id="company"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[5px] focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Enter company name"
                                    required
                                />
                            </div>

                            <div>
                                <label htmlFor="email" className="block mb-[10px] text-sm font-medium text-gray-900 dark:text-white">
                                    Email address
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[5px] focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Enter email address"
                                    required
                                    pattern="/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i"
                                />
                            </div>

                            <button type="submit" className="mt-12 h-[51px] bg-[#1C4863] rounded-[5px] text-white w-full text-sm font-inter-md font-semibold">
                                Get Guide
                            </button>
                        </form>
                    </div>
                </section>

                <section className=" body-padding py-20 mt-20">
                    <div>
                        <h4 className="font-inter-md text-xl ">Explore More Guides</h4>
                        <div className=" grid sm:grid-cols-[repeat(auto-fill,minmax(386px,1fr))] md:gap-7 mt-12">
                            {showLoading
                                ? Array.from({ length: 2 }).map((_, idx) => <PostCardSkeleton key={idx} />)
                                : guides.length
                                  ? guides.map((blog, idx) => (
                                        <PostCard buttonText="Get Now" baseUrl={'/resources/e-guides/'} width={'386px'} key={idx} post={blog} />
                                    ))
                                  : null}
                        </div>
                    </div>
                </section>
            </div>

            <div className="font-br-firma">
                <ContactForm />
                <Footer />
            </div>
        </>
    )
}

export default Guide
