import React from 'react'
import './about.scss'
import Founder from '../../assets/images/founder.jpg'
import Akin from '../../assets/images/akinphillips.jpg'
import Tobi from '../../assets/images/tObasa.jpeg'
import ABOUT3 from '../../assets/images/collegues.jpg'

const About = () => {
    return (
        <div className="about">
            <div className="sectionAboutUs fullpad">
                <h1>
                    We are focused on redefining <span>the future of haulage in Africa. </span>
                </h1>

                <p className="subabout">
                    We are a focused team of dynamic and passionate individuals driven by a vision to provide the best digital-based haulage and logistics
                    solution in Africa.
                </p>
            </div>

            <div className="teamImage">
                <div className="lineDes"></div>
                <img src={ABOUT3} alt="About Haul247" />
            </div>

            <div className="togetherAbout fullpad">
                <div className="lineThru"></div>

                <div className="sections">
                    <div className="section-1">Our Story.</div>

                    <div className="section-2">
                        <div className="topBar">
                            Haul247, an end-to-end logistics tech API platform that connects businesses to haulage and warehousing assets all in one ecosystem,
                            was created to simplify logistics process by ensuring efficient asset utilization, transparency and safety in the movement and
                            storage of goods.
                        </div>

                        <div className="story">
                            Our three founders are Sehinde Afolayan, Akindele Phillips, and Tobi Obasa. Akindele, Tobi, and Sehinde have known each other for
                            over 14 years since their university days at Obafemi Awolowo University, Nigeria.
                            {<br></br>} {<br></br>}
                            Sehinde picked this idea because it lies at the intersection of a genuine logistics problem and his core expertise, agriculture. A
                            few years ago, he traveled to the northern part of Nigeria to buy soy. On getting to the place where the grains were stored, he
                            noticed over half of the grains were spoilt due to poor storage conditions. He saw the need to do this better then went ahead to
                            discuss the idea with Akindele and Tobi. They both believed in the dream and now the journey is on 🎉
                        </div>
                    </div>
                </div>
            </div>

            <div className="togetherAbout tweak fullpad">
                <div className="lineThru"></div>

                <div className="sections">
                    <div className="section-1">Together we are super strong 💪</div>

                    <div className="section-2">
                        <div className="story">
                            We believe that transportation in Africa without the option of warehousing is expensive, inefficient, and relatively riskier. This
                            makes it hard for businesses to control, distribute and plan their logistics requirements end to end effectively.
                            {<br></br>} {<br></br>}
                            Our proprietary software allows individuals, farmers, manufacturers, FMCGs, to check for a quote and book services using a
                            three-step process. The software pushes an order request from a shipper to the platform, and then it attaches a quote and matches
                            the request with the right truck and warehouse for fulfillment. The system also allows the shipper to track the status of the goods
                            until it gets to their destination. This same process is also used by shippers and companies looking for warehouse facilities.
                            {<br></br>} {<br></br>}
                            With our “Air bnb" approach for trucks and warehouses, our system matches orders with available assets. Partners are assured reverse
                            hauling and shippers are charged less than what they normally pay, not only will this reduce the transportation cost but it will
                            also guarantee efficient asset utilization. Making it a win-win situation for both logistics providers and users.
                        </div>
                    </div>
                </div>
            </div>

            <div className="ceo_talk fullpad">
                "Our goal is to provide quality end-to-end logistics that gives value to our customers and maintains quality relationships with stakeholders"
                <div className="ceo_profile">
                    <div className="photo_img">
                        <img src={Founder} alt="Haul247 Founder" />
                    </div>

                    <div className="name_profile">
                        Sehinde Afolayan
                        <div className="post">Co-Founder & CEO</div>
                    </div>
                </div>
            </div>

            <div className="meet_the_team fullpad">
                <div className="text_meet">Meet our amazing team</div>

                <div className="lineThru"></div>

                <div className="team">
                    <div className="member">
                        <div className="image_placeholder">
                            <img src={Founder} width="300" height="300" alt="CEO - Seyinde Afolayan" loading="lazy" className="object-cover" />
                        </div>

                        <div className="name_holder">
                            <div className="fullname">Seyinde Afolayan</div>
                            <div className="post">Co-Founder, CEO</div>
                        </div>
                    </div>

                    <div className="member">
                        <div className="image_placeholder">
                            <img src={Akin} width="300" height="300" alt="COfounder - Akindele Phillips" loading="lazy" />
                        </div>

                        <div className="name_holder">
                            <div className="fullname">Akindele Phillips</div>
                            <div className="post">Co-Founder, CFO</div>
                        </div>
                    </div>

                    <div className="member">
                        <div className="image_placeholder tobi">
                            <img src={Tobi} width="300" height="300" alt="CTO - Tobi Obasa" loading="lazy" />
                        </div>

                        <div className="name_holder">
                            <div className="fullname">Tobi Obasa</div>
                            <div className="post">Co-Founder, CTO</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="togetherAbout fullpad">
                <div className="lineThru"></div>

                <div className="sections">
                    <div className="section-1">Join our team.</div>

                    <div className="section-2">
                        <div className="story">
                            We believe it takes great people to make a great product. That's why we hire not only the perfect professional fits, but people who
                            embody our company values and vision for transforming Africa.
                        </div>

                        <a href="/careers" className="careers_link">
                            <p>See open positions </p>

                            <div className="icon-xy">
                                <i className="fi fi-sr-arrow-square-right"></i>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
