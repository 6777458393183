import React, { useState } from 'react'
import ContactForm from '../../../components/ui/contactForm'
import Footer from '../../../components/ui/layout/footer'
import faq from '../../../data/faq.json'

const Faq = () => {
    const [active, setActive] = useState({ label: 'General', key: 'general' })

    const faqItems = [
        { label: 'General', key: 'general' },
        { label: 'Services', key: 'services' },
        { label: 'Pricing & Quotes', key: 'pricing' },
        { label: 'Haulage Services', key: 'haulage' },
        { label: 'Warehousing Services', key: 'warehousing' },
        { label: 'Logistic Services', key: 'logistic' },
        { label: 'Truck Owners', key: 'truck' },
        { label: 'Warehouse Owners. ', key: 'owners' },
    ]

    function getSelectedFaqContents() {
        return faq[active.key] || []
    }

    const selectedFaqContents = getSelectedFaqContents()
    return (
        <>
            <section className="bg-white font-inter text-[#242424] body-padding pb-20 pt-10 w-full">
                <h1 className="font-semibold text-2xl md:text-[40px] md:leading-[50px] mb-12">Frequently Asked Questions</h1>

                <div className="flex w-full gap-5 xl:gap-24 flex-col lg:flex-row">
                    <div className="lg:w-[200px] shrink-0">
                        <ul>
                            {faqItems.map((item, idx) => (
                                <li className={`h-[34px] w-full mb-2 `} key={idx}>
                                    <button
                                        onClick={() => setActive(item)}
                                        className={`w-full text-left text-sm leading-4  py-2 pl-6 pr-6 lg:pr-0 rounded  ${item.key === active.key ? 'bg-[#F4F4F4] border-l-8 border-indigo-500 text-[#242424] font-semibold font-inter-md' : 'text-[#5A5A5A]'}`}
                                    >
                                        {item.label}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div>
                        <div>
                            <h2 className="text-[26px] leading-[38px]">{active.label} FAQs</h2>

                            <ul className="mt-7">
                                {selectedFaqContents.map((item, idx) => (
                                    <li key={idx} className="mb-5">
                                        <p className="text-[15px] leading-[25px] font-inter-bold font-bold">
                                            {idx + 1}. {item.question}
                                        </p>
                                        <p className="text-[#777777] text-sm" dangerouslySetInnerHTML={{ __html: item.answer }} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <div className="font-br-firma ">
                <ContactForm />
                <Footer />
            </div>
        </>
    )
}

export default Faq
