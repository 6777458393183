import React from 'react'

const StatsCount = (props) => {
    return (
        <section className="body-padding-mobile bg-[#242424] text-white ">
            <div className={`grid grid-cols-auto  ${props.grid ? props.grid : 'md:grid-cols-3'}`}>
                {props.stats.map((el, idx) => (
                    <div key={idx} className={`py-11 md:first:pr-[54px] count ${el.active ? 'bg-[rgba(250,250,247,0.1)]' : ''} `}>
                        <h2 className="text-[#FAFAF7] text-[50px] leading-[51px] font-bold font-inter-md">{el.number}%</h2>
                        <p className={` ${props.textWidth ? `xl:${props.textWidth}` : 'xl:w-[324px]'} w-full  mt-[15px] text-sm font-bold`}>{el.text}</p>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default StatsCount
