import React from 'react'

const Cta = (props) => {
    function openContactForm() {
        /** Google Tag Manager custom event */
        window.dataLayer.push({
            event: 'click_cta',
        })

        const contactSection = document.getElementById('contact_sales')
        contactSection.scrollIntoView({ behavior: 'smooth' })
    }
    return (
        <section className="bg-[#FFFFF7] body-padding py-12  md:py-[100px] text-[#242424] ">
            <div className="mx-auto  flex flex-col justify-center items-center  text-center">
                <div className="text-[#F05822] bg-[#FFDDD1] px-7 py-[9px] w-fit rounded-full mb-[30px] font-inter-md text-[11px] leading-[13px] uppercase">
                    {props.pillText}
                </div>
                <h4
                    className={`font-inter-bold font-bold text-2xl lg:text-3xl lg:leading-[44px] w-full ${props.headingWidth ? props.headingWidth : 'lg:w-[783px]'}  mb-10`}
                >
                    {props.ctaHeading}
                </h4>
                <button
                    onClick={openContactForm}
                    className="text-sm font-inter rounded-[5px] px-4 md:px-[47px] py-[13px] text-white bg-gradient-to-r from-[#f0aa22] to-[#f05822]  font-semibold"
                >
                    {props.ctaButton}
                </button>
            </div>
        </section>
    )
}

export default Cta
