import React from 'react'

const StepByStep = () => {
    return (
        <section className="body-padding pt-10 md:pt-20 pb-[133px]">
            <div>
                <h2 className="text-[#242424] text-[28px] leading-[38px] font-bold font-inter-md">Step-by-Step Process. How It Works</h2>
                <div className="grid grid-cols-auto xl:grid-cols-2 gap-[22px] mt-10">
                    <div className="bg-[#FAFAF7] w-full xl:w-[597px] rounded-[13px] py-6 px-3 md:p-[25px]">
                        <div className="bg-[#FFF4DE] px-5 rounded-[40px] w-fit md:w-[224px] h-[34px] grid place-content-center mb-[34px]">
                            <p className="text-sm font-semibold text-[#F0AA22]">Want To Haul Your cargo?</p>
                        </div>

                        <div className="grid grid-cols-auto sm:grid-cols-2 md:px-[25px] gap-9 md:gap-[75px] text-[#242424]">
                            <div>
                                <p className="font-semibold  text-[15px] leading-[27px]">Step 1 </p>
                                <p className="text-sm">
                                    To register,{' '}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://client.haul247.co/login"
                                        className="text-[#F0AA22] border-b border-[#F0AA22] font-semibold"
                                    >
                                        click here
                                    </a>
                                </p>
                            </div>
                            <div>
                                <p className="font-semibold  text-[15px] leading-[27px]">Step 2 </p>
                                <p className="text-sm">Get onboarded on our platform and verified.</p>
                            </div>
                            <div>
                                <p className="font-semibold  text-[15px] leading-[27px]">Step 3 </p>
                                <p className="text-sm">Login into your dashboard and upload “trace request” (1 trip or multiple trips).</p>
                            </div>
                            <div>
                                <p className="font-semibold  text-[15px] leading-[27px]">Step 4 </p>
                                <p className="text-sm">Trips get automatically assigned and picked for loading.</p>
                            </div>

                            <div>
                                <p className="font-semibold  text-[15px] leading-[27px]">Step 5 </p>
                                <p className="text-sm">Customer track and get visibility on shipments via their logistics dashboard.</p>
                            </div>

                            <div>
                                <p className="font-semibold  text-[15px] leading-[27px]">Step 6 </p>
                                <p className="text-sm">The customer gets notified on shipment(s) tracking.</p>
                            </div>

                            <div>
                                <p className="font-semibold  text-[15px] leading-[27px]">Step 7 </p>
                                <p className="text-sm">Goods get delivered and customer can automatically download proof of delivery and invoice</p>
                            </div>

                            <div>
                                <p className="font-semibold  text-[15px] leading-[27px]">Step 8 </p>
                                <p className="text-sm">The customer gets notified on shipment(s) tracking.</p>
                            </div>
                        </div>
                    </div>

                    <div className="bg-[#FCF8F5] w-full xl:w-[597px] rounded-[13px] py-6 px-3 md:p-[25px]">
                        <div className="bg-[#FFE7D4] px-5  rounded-[40px] w-fit md:w-[406px] md:h-[34px] grid place-content-center mb-[34px] py-2">
                            <p className="text-sm font-semibold  text-[#F07B22]">Own a logistic asset (truck) and want to monetize it?</p>
                        </div>
                        <div className="grid grid-cols-auto sm:grid-cols-2 md:px-[25px] gap-9 md:gap-[75px] text-[#242424]">
                            <div>
                                <p className="font-semibold  text-[15px] leading-[27px]">Step 1 </p>
                                <p className="text-sm">Get registered on the platform and verified.</p>
                            </div>
                            <div>
                                <p className="font-semibold  text-[15px] leading-[27px]">Step 2 </p>
                                <p className="text-sm">Assets get verified and get access to their asset utilization tracking dashboard.</p>
                            </div>
                            <div>
                                <p className="font-semibold  text-[15px] leading-[27px]">Step 3 </p>
                                <p className="text-sm">Assets begin deployments and automatic trips loading.</p>
                            </div>
                            <div>
                                <p className="font-semibold  text-[15px] leading-[27px]">Step 4 </p>
                                <p className="text-sm">Get payments for loaded assets.</p>
                            </div>

                            <div>
                                <p className="font-semibold  text-[15px] leading-[27px]">Step 5 </p>
                                <p>Trips reports are generated via the dashboard</p>
                            </div>

                            <div>
                                <p className="font-semibold  text-[15px] leading-[27px]">Step 6 </p>
                                <p className="text-sm">Monthly assets utilization and management reports</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StepByStep
