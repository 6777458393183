import { Helmet } from 'react-helmet'

const SEOTag = ({ title, description, url, image }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="image" content={image} />
            <link rel="canonical" href={url} />
            {/* OpenGraph tags */}
            <meta property="og:title" content={title} key="ogtitle" />
            <meta property="og:url" content={url} />
            <meta property="og:image" content={image} />
            <meta property="og:description" content={description} key="ogdesc" />
            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@haul247" />
            <meta name="twitter:creator" content="@haul247" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
        </Helmet>
    )
}

export default SEOTag
