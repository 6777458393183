import React from 'react';

const ImpactItem = () => {

    const data = [

        {
            
            title : "Brand Loyalty",
            icon : "ss-heart",
            p : "An excellent customer experience is the driving factor behind repeat sales. By delivering orders accurately and quickly, we have improved the customer experience which in turn increased brand loyalty.",
            color : "#FF004E",
            bg : "#FFEDF2"

        },

        {
            
            title : "Prevention of loss",
            icon : "sr-shield-check",
            p : "At Haul247, our farmer and manufacturing clients have experienced drastic reduction in loss in several ways. One is by a true inventory accounting at our partners' spatial warehouses, which helped with stock taking. They have also been able to track movement and current location so goods won’t be misplaced or diverted without notice using our real-time updates. In addition, by ensuring optimal storage and transport conditions, such as temperature and moisture management, spoilage of farm produce and stock has been reduced before it gets to the final consumer.",
            color : "#FFA700",
            bg : "#FFF2D9"

        },

        {
            
            title : "Economic development through job creation",
            icon : "sr-bank",
            p : "We are creating direct and indirect jobs through proper utilization of partners' income-generating assets and our technology. This in turn improves the economic stability of every life involved.",
            color : "#438FFE",
            bg : "#EAF7FF"

        }

    ]

    return (

        data.map( (res, index) => {

            return <div className="impactItem">

            <div className="content">

                <div className="title" style={{backgroundColor : `${res.bg}`, color : `${res.color}` }}> <i class={`fi fi-${res.icon}`}></i> <p>{res.title}</p></div>

                <div className="body">

                    <p className='top'>
                        
                        {res.p} 

                    </p>

                </div>

            </div>

        </div>


        } )

        
    );
}

export default ImpactItem;
