import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const contentFormat = {
    renderMark: { [MARKS.BOLD]: (text) => <span className="font-bold">{text}</span> },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <p className="text-[#777777] text-[15px] leading-[25px] my-4">{children}</p>,
        [BLOCKS.HEADING_1]: (node, children) => <h1 className="font-bold font-inter-md text-4xl mb-7">{children}</h1>,
        [BLOCKS.HEADING_2]: (node, children) => <h2 className="font-bold font-inter-md text-3xl mb-7">{children}</h2>,
        [BLOCKS.HEADING_3]: (node, children) => <h3 className="font-semibold font-inter-md text-2xl mb-7">{children}</h3>,
        [BLOCKS.HEADING_4]: (node, children) => <h4 className="font-semibold text-xl mb-5 mt-4">{children}</h4>,
        [BLOCKS.HEADING_5]: (node, children) => <h5 className="font-semibold text-lg mb-3 mt-3">{children}</h5>,
        [BLOCKS.HEADING_6]: (node, children) => <h6 className="font-semibold text-base mb-1 mt-3">{children}</h6>,
        [BLOCKS.OL_LIST]: (node, children) => <ol className="list-decimal my-5 ms-4 ">{children}</ol>,
        [BLOCKS.UL_LIST]: (node, children) => <ol className="list-disc my-5 ms-4 ">{children}</ol>,
        [BLOCKS.LIST_ITEM]: (node, children) => <li className="text-[#242424] text-[15px] list-items">{children}</li>,
        [INLINES.HYPERLINK]: (node, children) => (
            <a href={node.data.uri} target="_blank" rel="noreferrer" className="text-[#0072FF]">
                {children}
            </a>
        ),
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
            const { title, description, file } = node.data.target.fields
            const mimeType = file.contentType
            const mimeGroup = mimeType.split('/')[0]

            switch (mimeGroup) {
                case 'image':
                    return <img title={title ? title : null} alt={description ? description : null} src={file.url} className="w-full h-auto my-5" />
                default:
                    return <span style={{ backgroundColor: 'red', color: 'white' }}> {mimeType} embedded asset </span>
            }
        },
    },
    preserveWhitespace: true,
}

export { contentFormat, documentToReactComponents }
