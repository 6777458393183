import React from 'react'
import WarehouseIcon from '../../assets/icons/warehouse.svg'
import PortIcon from '../../assets/icons/port.svg'
import SupplyIcon from '../../assets/icons/supplyChain.svg'
import Hero from '../../components/ui/hero'
import BenefitSection from '../../components/ui/benefitSection'
import Brands from '../../components/ui/brands'
import StepByStep from '../../components/ui/stepByStep'
import Pages from '../../components/ui/pages'
import StatsCount from '../../components/ui/statsCount'
import CartonIcon from '../../assets/icons/benefits/carton.svg'
import HandIcon from '../../assets/icons/benefits/hand.svg'
import SecureIcon from '../../assets/icons/benefits/secure.svg'
import SpannerIcon from '../../assets/icons/benefits/spanner.svg'
import TimerIcon from '../../assets/icons/benefits/timer.svg'
import ScalabilityIcon from '../../assets/icons/benefits/scalability.svg'
import TruckIcon from '../../assets/icons/benefits/trucks.svg'
import Faq from '../../components/ui/faq'
import ContactForm from '../../components/ui/contactForm'
import Footer from '../../components/ui/layout/footer'
import Cta from '../../components/ui/cta'
import SEOTag from '../../components/seoTag'

const Warehousing = () => {
    return (
        <>
            <SEOTag
                image=""
                title="Haul247 | Smart Warehouse Solutions | Real-time Inventory Control| Order Fulfillment "
                url="https://www.haul247.co/products/warehousing"
                description="Discover a smarter way to manage your inventory and streamline order fulfillment. Our smart warehouse solutions help businesses to optimize their warehouse operations, reduce costs, and enhance customer satisfaction. From real-time tracking and automated inventory control to seamless order fulfillment, we empower businesses to optimize their supply chain. We safeguard and enhance your inventory, propelling your business towards success. We ensure that your goods are stored securely while optimizing space utilization."
            />

            <div className="font-inter text-[#242424]">
                <Hero
                    icon={WarehouseIcon}
                    heading={'Space Perfected, Inventory Protected'}
                    subHeading={`A well-managed inventory is the backbone of successful logistics. Haul247 Warehousing Solutions aren't just about storage; they're about optimizing your inventory to drive your business forward.`}
                    buttonText={'Unlock Warehousing Efficiency Now!'}
                    link={'/contact'}
                />

                <BenefitSection
                    benefits={[
                        {
                            heading: 'Space Optimization',
                            subText: `From every square foot to every pallet, we maximize space for your inventory, ensuring every inch counts without compromising accessibility.`,
                            icon: CartonIcon,
                        },
                        {
                            heading: 'Precision Inventory Management',
                            subText: `Experience the confidence of precise inventory control. Our systems and teams ensure accurate tracking and seamless management of your goods.`,
                            icon: HandIcon,
                        },
                        {
                            heading: 'Security and Safety',
                            subText: `Your peace of mind is our priority. Our warehouses are fortified with cutting-edge security measures and adhere to stringent safety standards.`,
                            icon: SecureIcon,
                        },
                        {
                            heading: 'Customized Approach',
                            subText: `Your business is unique, and so are our solutions. We tailor our warehousing services to align perfectly with your industry, products, and storage requisites`,
                            icon: SpannerIcon,
                        },
                        {
                            heading: 'Efficiency Redefined',
                            subText: `Say goodbye to bottlenecks. Our optimized layouts and streamlined processes pave the way for a more efficient inventory management system.`,
                            icon: TimerIcon,
                        },
                        {
                            heading: 'Scalability & Flexibility',
                            subText: `Grow confidently. Our flexible warehousing options expand or contract with your business needs, ensuring seamless transitions.`,
                            icon: ScalabilityIcon,
                        },
                    ]}
                />

                <Brands />

                <StatsCount
                    stats={[
                        { number: 30, text: 'Increased storage capacity by optimizing space without compromising accessibility', active: false },
                        { number: 95, text: 'Accuracy rate in inventory management,minimizing discrepancies and losses.', active: true },
                        {
                            number: 20,
                            text: 'Reduction in storage costs through our space optimization strategies, enabling us to allocate resources more effectively.',
                            active: false,
                        },
                    ]}
                />

                <StepByStep />

                <Pages
                    pages={[
                        {
                            title: 'Port Operations',
                            description:
                                'From documentation to efficient cargo movement, trust us to navigate ports effortlessly. We handle the complexities while you focus on your business growth.',
                            link: '/products/port-operations',
                            icon: PortIcon,
                        },
                        {
                            title: 'Supply Chain Optimization',
                            description: 'We analyze and strategize and implement tailored solutions for efficiency and cost-effectiveness.',
                            link: '/products/supply-chain',
                            icon: SupplyIcon,
                        },
                        {
                            title: 'Haulage Solutions',
                            description: `Trust us to deliver your cargo swiftly, securely, and with precision, ensuring it reaches its destination on time, every time.`,
                            link: '/products/haulage',
                            icon: TruckIcon,
                        },
                    ]}
                />

                <div className="font-br-firma">
                    <div className="bg-[#FFFFF7]">
                        <Faq
                            faqs={[
                                {
                                    question: 'What types of storage solutions do you offer?',
                                    answer: `Our warehouses cater to various needs, including ambient, temperature-controlled,and secure storage options.`,
                                },
                                {
                                    question: 'Can I access my goods stored in your warehouses?',
                                    answer: `Yes, we provide access to your goods based on agreed-upon terms and conditions.`,
                                },
                                {
                                    question: 'How do you ensure the security of stored items?',
                                    answer: `Our warehouses are equipped with modern security systems, surveillance, and stringent access controls to safeguard your goods.`,
                                },
                                {
                                    question: `Is there a limit on the duration of storage?`,
                                    answer: `We offer flexible storage durations tailored to your specific requirements.`,
                                },
                                {
                                    question: `Can you handle inventory management?`,
                                    answer: `Absolutely, our inventory management systems ensure accurate tracking,reporting, and management of your inventory.`,
                                },
                            ]}
                        />
                    </div>

                    <Cta
                        pillText={'Ready to revolutionize your warehousing experience?'}
                        ctaHeading={'Unlock unparalleled visibility and control over your shipments.'}
                        ctaButton={'Experience Optimized Warehousing Solutions Today!'}
                        headingWidth={'lg:w-[703px]'}
                    />

                    <ContactForm />

                    <Footer />
                </div>
            </div>
        </>
    )
}

export default Warehousing
