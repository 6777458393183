import React from 'react'

const PostCardSkeleton = () => {
    return (
        <div className="h-[404px] animate-pulse">
            <div className="h-[200px] bg-gray-400"></div>
            <div className="mt-5 mb-[30px]">
                <div className="h-4 bg-gray-400 w-3/4"></div>
                <div className="mt-5 h-6 bg-gray-400 w-1/2"></div>
            </div>
            <button className="border border-[#999999] h-[46px] rounded-md text-sm px-11 py-3 bg-gray-400"></button>
        </div>
    )
}

export default PostCardSkeleton
