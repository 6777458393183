import React, { useEffect, useState } from 'react'
import LeftArrow from '../../../assets/icons/left-arrow.svg'
import { Link } from 'react-router-dom'
import ContentfulClient from '../../../utils/contentfull/contentfull-v2.js'
import { useParams, useSearchParams } from 'react-router-dom'
import { contentFormat, documentToReactComponents } from '../../../utils/contentfull/contentfull-text'
import ContactForm from '../../../components/ui/contactForm'
import Footer from '../../../components/ui/layout/footer'

const GlossarySubject = () => {
    const contentful = new ContentfulClient()
    const { slug, section } = useParams()
    let [searchParams] = useSearchParams()
    const contentType = searchParams.get('contentType')

    const [loading, setLoading] = useState(true)
    const [glossaries, setGlossaries] = useState([])
    const [selectedGlossary, setSelectedGlossary] = useState(null)

    useEffect(() => {
        fetchGlossaries()
        getSingleGlossary()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug])

    const fetchGlossaries = async () => {
        const glossaries = await contentful.getEntries(section)
        if (glossaries) {
            const filtered = glossaries
                .filter((gl) => gl.slug !== slug)
                .sort(() => 0.5 - Math.random())
                .slice(0, 3)
            setGlossaries(filtered)
            console.log('filtered', filtered)
        }
    }

    const getSingleGlossary = async () => {
        setLoading(true)
        const glossaryContent = await contentful.getEntry(section, slug)
        if (glossaryContent) {
            setSelectedGlossary(glossaryContent)
        }
        console.log('selected', glossaryContent)
        setLoading(false)
    }

    function openContactForm() {
        /** Google Tag Manager custom event */
        window.dataLayer.push({
            event: 'click_cta',
        })

        const contactSection = document.getElementById('contact_sales')
        contactSection.scrollIntoView({ behavior: 'smooth' })
    }

    const glossaryContent = documentToReactComponents(selectedGlossary?.subjectContent, contentFormat)

    return (
        <>
            <div className="font-inter">
                <section className="body-padding bg-[#1C4863] pt-6 pb-10 md:pt-[85px] md:pb-[69px] text-white">
                    <div className=" w-full md:w-[900px]">
                        <div className="flex flex-row mb-11">
                            <img src={LeftArrow} alt="icon" />
                            <Link to="/resources/glossary" className="ml-2 font-bold text-sm font-inter-md">
                                Back to Glossary
                            </Link>
                        </div>
                        <p className="mb-10">
                            Glossary: <span className="uppercase">{contentType}</span>
                        </p>
                        {loading ? (
                            <div className="animate-pulse">
                                <h1 className="font-bold font-inter-bold text-4xl mb-5 bg-gray-200 h-12 rounded text-transparent">""</h1>
                                <h6 className="text-[15px] leading-[27px] md:w-[800px] bg-gray-200 h-6 rounded text-transparent">""</h6>
                            </div>
                        ) : (
                            <>
                                <h1 className="font-bold font-inter-bold text-2xl md:text-4xl mb-5">{selectedGlossary?.subject}</h1>
                                <h6 className="text-sm md:text-[15px] leading-[27px] md:w-[800px]">{selectedGlossary?.subTitle}</h6>
                            </>
                        )}
                    </div>
                </section>

                <section id="blog-post" className="body-padding mt-20 mb-20 md:mb-[100px]">
                    <div className="flex flex-col lg:flex-row gap-10 md:gap-24">
                        <div className="">
                            {loading ? (
                                <div className="animate-pulse">
                                    <div className="md:w-[800px]">
                                        <div className="h-10 bg-gray-300 w-full "></div>
                                        <div className="h-12 bg-gray-300 w-full mt-5"></div>
                                        <div className="h-24 bg-gray-300 w-full mt-5"></div>
                                        <div className="h-24 bg-gray-300 w-full mt-5"></div>
                                        <div className="h-24 bg-gray-300 w-full mt-5"></div>
                                        <div className="h-24 bg-gray-300 w-full mt-5"></div>
                                    </div>
                                </div>
                            ) : glossaryContent ? (
                                <div className=" list-outside">{glossaryContent}</div>
                            ) : (
                                <div className=" mx-auto  bg-white shadow-lg rounded-lg p-6 text-center">
                                    <p className="text-xl font-bold">Content not available yet</p>
                                </div>
                            )}
                        </div>

                        <div className="md:w-[381px] flex-shrink-0 p-8 bg-[#FAFAF7] h-[250px] flex flex-col">
                            <h6 className="font-bold font-inter-bold text-sm leading-[27px] mb-[10px]">Ready to ship?</h6>
                            <p className="text-[#4D4D4D] font-semibold text-sm leading-6 ">Haul with us and be rest assured your logistics are in good hands</p>

                            <button
                                onClick={openContactForm}
                                className="text-sm mt-auto font-inter rounded-[5px] px-4 md:px-[47px] py-[13px] text-white bg-gradient-to-r from-[#f0aa22] to-[#f05822]  font-semibold"
                            >
                                Speak to an Expert
                            </button>
                        </div>
                    </div>
                </section>

                {glossaries.length > 0 && (
                    <section className="body-padding pt-5 pb-9  bg-[#FAFAFA] ">
                        <div className="pt-5">
                            <h6 className="text-[#1C4863] font-semibold font-inter-md mb-10 text-xl">Related Glossaries</h6>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
                                {glossaries.map((glossary, idx) => (
                                    <Link
                                        to={`/resources/glossary/${section}/${glossary.slug}?contentType=${contentType}`}
                                        key={idx}
                                        className="bg-white shadow-lg rounded-lg p-6"
                                    >
                                        <h6 className="font-bold font-inter-bold text-sm leading-[27px] mb-[10px]">{glossary.subject}</h6>
                                        <p className="text-[#4D4D4D] font-semibold text-sm leading-6">{glossary.subTitle}</p>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </section>
                )}
            </div>

            <div className="font-br-firma">
                <ContactForm />
                <Footer />
            </div>
        </>
    )
}

export default GlossarySubject
