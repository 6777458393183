import React from 'react'
import ContactForm from '../../../components/ui/contactForm'
import Footer from '../../../components/ui/layout/footer'
import videos from '../../../data/videos.json'
import { Link } from 'react-router-dom'

const Videos = () => {
    return (
        <>
            <section className="bg-white font-inter text-[#242424] body-padding pb-20">
                <h1 className="font-semibold text-2xl md:text-[40px] md:leading-[50px] my-12">Haul247 Videos</h1>
                <div className="grid sm:grid-cols-[repeat(auto-fill,minmax(300px,1fr))] gap-8">
                    {videos.posts.map((video, idx) => (
                        <div className="h-96">
                            <div className="h-[55%]">
                                <img
                                    src={`https://ytimg.googleusercontent.com/vi/${video.url.split('v=')[1]}/sddefault.jpg`}
                                    alt={video.title}
                                    className="w-full h-full object-cover"
                                />
                            </div>
                            <div className="h-[40%] mt-5">
                                <h3 className="text-base mb-[6px] font-bold">{video.title}</h3>
                                <p className="h-20 block text-sm font-semibold text-[#777777]">{video.content.slice(0, 100) + '...'}</p>
                                <Link
                                    to={`/resources/video/${video.id}`}
                                    className="border border-[#999999] h-[46px] rounded-md text-sm px-11 py-3 hover:bg-slate-300 hover:border-opacity-0 hover:text-white"
                                >
                                    Watch Now
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <div className="font-br-firma ">
                <ContactForm />
                <Footer />
            </div>
        </>
    )
}

export default Videos
