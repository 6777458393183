import React from 'react'
import Hero from '../../components/ui/hero'
import ProtectionIcon from '../../assets/icons/protection.svg'
import BenefitSection from '../../components/ui/benefitSection'
import UmbrellaIcon from '../../assets/icons/benefits/umbrella.svg'
import TailoredIcon from '../../assets/icons/benefits/tailored.svg'
import InsuredIcon from '../../assets/icons/benefits/insured.svg'
import RiskIcon from '../../assets/icons/benefits/risk.svg'
import StampedIcon from '../../assets/icons/benefits/stamped.svg'
import EfficientIcon from '../../assets/icons/benefits/efficient.svg'
import Faq from '../../components/ui/faq'
import ContactForm from '../../components/ui/contactForm'
import Footer from '../../components/ui/layout/footer'
import Testimonial from '../../components/ui/testimonial'
import Cta from '../../components/ui/cta'
import SEOTag from '../../components/seoTag'

const Insurance = () => {
    return (
        <>
            <SEOTag image="" title="Haul247 | Transit Insurance" url="https://www.haul247.co/solutions/insurance" description="" />
            <div className="font-inter text-[#242424]">
                <Hero
                    icon={ProtectionIcon}
                    heading={'Safeguarding Your Shipments, Securing Your Peace of Mind'}
                    subHeading={`We understand the value of your cargo hence the transit insurance solution isn’t just protection; it’s your shield against uncertainties, ensuring your shipments reach their destination safely and securely.`}
                />

                <section className="mb-20">
                    <BenefitSection
                        benefits={[
                            {
                                heading: 'Comprehensive Coverage, Complete Confidence',
                                subText: `We offer robust transit insurance that covers your goods against a wide range of risks during transportation. From damage to theft, our coverage provides you with the peace of mind you deserve.`,
                                icon: UmbrellaIcon,
                            },
                            {
                                heading: 'Tailored Protection for Your Cargo',
                                subText: `No two shipments are the same. That's why our transit insurance solutions are flexible and customizable, catering to the unique needs of your cargo and offering the right level of protection.`,
                                icon: TailoredIcon,
                            },
                            {
                                heading: 'Effortless Claims Process',
                                subText: `In the unfortunate event of a mishap, our streamlined claims process ensures a quick and hassle-free experience. We're committed to getting you back on track with minimal disruptions.`,
                                icon: InsuredIcon,
                            },
                            {
                                heading: 'Risk Mitigation',
                                subText: `Protect your investments. Our transit insurance minimizes the financial risks associated with transportation, ensuring that any unforeseen events don't lead to substantial losses.`,
                                icon: RiskIcon,
                            },
                            {
                                heading: 'Enhanced Reliability',
                                subText: `Offer your customers peace of mind. With our insurance, you demonstrate a commitment to delivering goods safely, enhancing your reputation for reliability and trustworthiness.`,
                                icon: StampedIcon,
                            },
                            {
                                heading: 'Cost-Efficient Solutions',
                                subText: `Our transit insurance is not just about protection; it's an investment in your peace of mind. The minimal cost involved outweighs the potential losses in case of any unfortunate incidents.`,
                                icon: EfficientIcon,
                            },
                        ]}
                    />
                </section>

                <section className=" bg-[#242424] text-white ">
                    <div className={`grid grid-cols-auto md:grid-cols-2`}>
                        <div className={`py-11 px-6 lg:pl-[112px] bg-[rgba(250,250,247,0.1)] `}>
                            <h2 className="text-[#FAFAF7] text-[50px] leading-[51px] font-bold font-inter-md">50%</h2>
                            <p className={`  w-full  mt-[15px] text-sm font-bold`}>Decrease in Financial Losses Due to Damage or Loss</p>
                        </div>

                        <div className="py-11 px-6 lg:px-[112px]">
                            <p className="font-bold font-inter-md text-3xl leading-[44px]">Efficient Claims Process for Minimal Disruptions</p>
                        </div>
                    </div>
                </section>

                <Testimonial
                    role={`Transport Manager, TGI`}
                    writer={`Kareem Maji`}
                    message={`We have used different haulage partners in the past but we normally have to work with so many multiple partners to move and warehouse our goods. With Haul247 this has become easier for us because they do both effectively. We can easily make our trips and ware house requests on our dashboard and the system does the rest.`}
                />
            </div>
            <div className="font-br-firma">
                <div className="bg-[#FFFFF7]">
                    <Faq
                        faqs={[
                            {
                                question: 'What does transit insurance cover?',
                                answer: `Our transit insurance covers a wide range of risks including damage, theft, loss,and other unforeseen incidents that may occur during transportation. Specific coverage details can be tailored to meet your cargo&#39;s unique needs.`,
                            },
                            {
                                question: 'Is the transit insurance customizable based on the type of cargo?',
                                answer: `Absolutely. We understand that each shipment is unique. Our transit insurance solutions are flexible and customizable, ensuring that your cargo receives the appropriate level of coverage based on its nature and value.`,
                            },
                            {
                                question: 'How does transit insurance benefit my business?',
                                answer: `Transit insurance significantly mitigates financial risks associated with transportation. It ensures that in case of any unfortunate events during transit,your cargo is protected, minimizing potential losses and maintaining your business&#39;s financial stability.`,
                            },
                            {
                                question: `Are there any limitations or exclusions in the coverage?`,
                                answer: `While our transit insurance covers a broad spectrum of risks, specific exclusions may apply. These typically include pre-existing damage, inadequate packaging, and certain types of goods. We&#39;ll work closely with you to explain any exclusions and ensure clarity in coverage.`,
                            },
                            {
                                question: `How is the claims process for transit insurance handled?`,
                                answer: `Our claims process is designed to be swift and efficient. In the event of a claim, our dedicated team will guide you through the process, ensuring minimal disruptions to your operations and a quick resolution to your satisfaction.`,
                            },
                            {
                                question: `Is the cost of transit insurance worth the investment?`,
                                answer: `Absolutely. The minimal cost of transit insurance far outweighs the potential financial losses your business could incur due to damage or loss during transportation. It&#39;s an investment in protecting your cargo and ensuring peace of mind.`,
                            },
                            {
                                question: `How do I get started with transit insurance for my shipments?`,
                                answer: `Getting started is easy. Simply reach out to our team, and we&#39;ll guide you through the process, explaining the available options, and coverage details, and assisting you in securing transit insurance for your shipments.`,
                            },
                        ]}
                    />
                </div>

                <Cta
                    pillText={'Ready to safeguard your shipments and minimize risks?'}
                    ctaHeading={`Explore our transit insurance solutions and ensure the safety of our valuable cargo during transportation.`}
                    ctaButton={`Get Secured`}
                    headingWidth={'lg:w-[945px]'}
                />

                <ContactForm />

                <Footer />
            </div>
        </>
    )
}

export default Insurance
